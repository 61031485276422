<template>
  <div id="hey">

    <header>
      <px-nav id="px-nav" class="fixed block shadow-sm"
              @menuClicked="toggleAside()">
      </px-nav>
    </header>

    <px-aside id="px-aside"
              class="hidden fixed"
              @getFavsClick="toggleAside()"
              @getCategoriesClick="toggleAside()"
              @getPhrasesClick="toggleAside()">
    </px-aside>

    <router-view id="px-pictos">

    </router-view>

    <px-constructor  id="px-constructor" class="shadow-sm hide-constructor min-height-constructor" @clickConstructor="toggleConstructor">

    </px-constructor>

  </div>
</template>

<script>
import PxNav from "@/components/PxNav";
import PxAside from "@/components/PxAside";
import PxConstructor from "@/components/PxConstructor";
import {mapActions, mapState} from 'vuex';

export default {
  name: 'App',

  components: {
    PxNav,
    PxAside,
    PxConstructor
  },

  data(){
    return{
      loadingPhrase: 1,
      isCategoriesClicked: false,
      isFavsClicked: false,
      orderContainers: false,
    }
  },

  created() {

    this.getAuthorization();

    this.getDefaultPhrases();

    this.getMyCategories()/*.then(()=>{
      if(this.$store.state.user.email !== null && this.$store.state.user.email === 'elcorreoesdejulio@gmail.com')
        this.upVerbs();
    });*/

    this.getPictoArasaac();

    this.getEmptyCategories();

    this.getVoices();

    this.getVerbs();

  },

  mounted() {

  },

  methods:{

    ...mapActions(['getMyCategories', "getPictoArasaac", "getEmptyCategories", "getDefaultPhrases", "getVoices", "getVerbs"]),

    toggleAside: function (){
      let pxAside = document.getElementById('px-aside');
      this.showAside = !this.showAside;

      if (this.showAside){
        if (pxAside.classList.contains('hidden')){

          pxAside.classList.remove('hidden');
          pxAside.classList.add('show-aside');

          if (pxAside.classList.contains('hide-aside'))
            pxAside.classList.remove('hide-aside');
        }

      }else{

        if (pxAside.classList.contains('show-aside')){
          pxAside.classList.remove('show-aside');
          pxAside.classList.add('hide-aside');

          setTimeout(() => {
            pxAside.classList.add('hidden');
          }, 300);

        }
      }

    },

    toggleConstructor: function (){
      let pxConstructor = document.getElementById('px-constructor');

      if (pxConstructor.classList.contains('show-constructor') && pxConstructor.classList.contains('max-height-constructor')){
        pxConstructor.classList.remove('show-constructor');
        pxConstructor.classList.remove('max-height-constructor');
        pxConstructor.classList.add('hide-constructor');
        setTimeout(() =>{
          pxConstructor.classList.add('min-height-constructor')
        }, 300)
      }else if(pxConstructor.classList.contains('hide-constructor') && pxConstructor.classList.contains('min-height-constructor')){
        pxConstructor.classList.add('show-constructor');
        pxConstructor.classList.remove('hide-constructor');
        pxConstructor.classList.remove('min-height-constructor');
        setTimeout(()=>{
        pxConstructor.classList.add('max-height-constructor');
        }, 300);
      }

    },

    getAuthorization: function (){
      if (!localStorage.getItem('token')) return false;

      let token = localStorage.getItem('token');
      var myHeaders = new Headers();

      myHeaders.append('Authorization', token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${this.$store.state.server}user`, requestOptions)
          .then(response => {
            if (response.status === 401){
              localStorage.removeItem('token');
              this.$router.push('/login');
              return false;
            }
            return response.json();
          })
          .then(response => this.$store.commit('setUser', response.body))
          .catch(error => console.log('error', error));
    },

    upVerbs: function (){
      if (this.categories === null) return false;

      let verbs = this.categories.verbos.en.filter(verb => typeof verb === 'object');
      let verbKeywords = [];

      for (let object in verbs){
        if (!verbKeywords.includes(verbs[object].keywords[0].keyword)){
          verbKeywords.push(verbs[object].keywords[0].keyword);
        }
      }

      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', localStorage.getItem('token'));

      for(let verb in verbKeywords){
        setTimeout(() =>{
          fetch(`${this.$store.state.server}verbs/add`,{
            method: 'POST',
            headers,
            body: JSON.stringify(
                {
                  verb: {
                    infinitive: verbKeywords[verb],
                  }
                }
            ),
            redirect: 'follow',
          })
        },1000);
      }

    },

  },

  computed:{
    ...mapState(['categories', 'user']),
  },

  watch:{
    isFavsClicked: function (){
      this.toggleAside();
    },

    isCategoriesClicked: function (){
      this.toggleAside();
    },

    categories: function (){
      let constructor = document.getElementById('px-constructor');

      if (constructor.classList.contains('min-height-constructor')){
        constructor.classList.remove('min-height-constructor');
        constructor.classList.remove('hide-constructor');
        constructor.classList.add('max-height-constructor');
        constructor.classList.add('show-constructor');
      }
    }
  },

}
</script>

<style>
html{
  background: rgb(255, 243, 224);
}
#app {
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#hey{
  height: 0;
}

#px-aside{
  height: calc(100vh - 96px);
  top: 96px;
}

@keyframes show-aside {
  0%{
    width: 0;
  }
  100%{
    width: 13%;
  }
}

@keyframes hide-aside {
  0%{
    width: 13%;
  }
  100%{
    width: 0;
  }
}

.show-aside{
  animation: show-aside .3s linear;
}

.hide-aside{
  animation: hide-aside .3s linear;
}


@media (max-width: 1024px){
  #px-aside{
    height: calc(100vh - 81px);
    top: 80px;
  }
}

#px-pictos, #px-loading, #px-picto-fav{
  position: relative;
  top: 96px;
  z-index: -1;
}

#px-constructor{
  position: fixed;
  bottom: 0;
  background: rgb(255, 249, 240);
  width: 100vw;
  border-top: 1px solid rgb(238, 217, 204);
  max-height: 37.5vh;
}

@keyframes show-constructor {
  0%{
    height: 5.5vh;
  }
  100%{
    height: 37.5vh;
  }
}

@keyframes hide-constructor {
  0%{
    height: 37.5vh;
  }
  100%{
    max-height: 5.5vh;
  }
}

.show-constructor{
  animation: show-constructor .3s linear;
}

.hide-constructor{
  animation: hide-constructor .3s linear;
}

.max-height-constructor{
  height: 37.5vh !important;
}

.min-height-constructor{
  max-height: 5.5vh !important;
}

</style>