<template>
<px-phrase></px-phrase>
</template>

<script>

import PxPhrase from "@/components/PxPhrase";

export default {

  name: "Phrase",
  components:{
  PxPhrase
  }
}
</script>

<style scoped>

</style>