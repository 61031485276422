<template>
<div class="container">
  <h1 class="text-4xl">Formulario de registro</h1>

  <form action="" @submit.prevent="">
    <span v-if="showMsg" :class="signOn ? 'log-msg-ok' :'log-msg-err'">{{signOn ? 'Te has registrado correctamente' : 'El usuario ya existe o la contraseña no coincide'}}</span>
    <span v-if="showLoginError" class="log-msg-err">El usuario o la contraseña no coincide</span>
    <div>
      <label for="email"><img src="https://api.arasaac.org/api/pictograms/27355" alt="Email"><div><span> Introduce tu correo electronico</span></div></label>
      <input type="email" v-model="user.email" name="email" id="email" placeholder="Correo electronico" required="required">
    </div>
    <div>
      <label for="password"><img src="https://api.arasaac.org/api/pictograms/27691" alt="Contraseña"><div><span>{{isRegister ? 'Introduce tu contraseña' : 'Introduce una contraseña'}}</span></div></label>
      <input type="password" v-model="user.password" name="password" id="password" placeholder="Contraseña" required="required">
    </div>
    <div>
      <label for="repeated" v-if="!isRegister"><img src="https://api.arasaac.org/api/pictograms/37162" alt="Contraseña"><div><span> Repite la contraseña</span></div></label>
      <input type="password" v-model="repeated" name="repeated" id="repeated" v-if="!isRegister" placeholder="Repite la contraseña" required="required">
    </div>
    <div>
      <input class="form-btn" type="submit" :value="isRegister? 'Login' : 'Registrate' " @click="isRegister ? login() : signIn()">
      <div @click="deleteToken" class="form-btn">Iniciar sin registro</div>
      <span class="options" v-if="isRegister">No recuerdo la contraseña</span>
      <span class="options" @click="toggleRegister" :class="isRegister ? 'hidden' : ''">Ya estoy registrado</span>
      <span class="options" @click="toggleRegister" :class="isRegister ? '' : 'hidden'">No estoy registrado</span>
    </div>
  </form>
</div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "PxLogin",

  data(){
    return{
      isRegister: true,
      user:{
        email: '',
        password: '',
        favourites: '',
      },
      repeated: '',
      signOn: null,
      showMsg: false,
      showLoginError: false,
    }
  },

  methods:{

    ...mapActions(['getUser']),

    toggleRegister: function (){
      this.isRegister = !this.isRegister
    },

    login: function (){
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let {email, password} = this.user;

      let raw = JSON.stringify({email: email , password : password});

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${this.$store.state.server}user/login`, requestOptions)
          .then(response => {
            if (response.status === 200){
              return response.json();
            }else {
              this.showLoginError = true;
              this.user.password = '';
              document.getElementById('password').focus();
            }
          })
          .then(response => {
              localStorage.setItem('token', response.body.token);
              this.getUser(response.body.token)
                  .then(() => this.$router.push('/me'));

          })
          .catch(error => console.log('error', error));
    },

    signIn: function (){
      let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; //w3.org
      let {email, password} = this.user;

      if (password.length < 8){
        return false;
      }

      if (emailRegex.test(email) && password === this.repeated){
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({email, password});

        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(`${this.$store.state.server}user/add`, requestOptions)
            .then(response => {
              if (response.status === 201){
                return response.json();
              }else{
                this.signOn = false;
                this.showMsg = true;
                password = '';
                this.repeated = '';
              }
            })
            .then( () => {
                this.signOn = true;
                this.showMsg = true;
                this.user.password = '';
                this.repeated = '';
            })
            .catch(error => console.log('error', error));
      }

    },

    deleteToken: function (){
      this.$router.push('/');
      if (localStorage.getItem('token')){
        localStorage.removeItem('token');
      }
    },
  },

}
</script>

<style scoped>
  div{
    display: flex;
    flex-direction: column;
  }
  div > h1{
    text-align: center;
    color: rgb(114, 47, 13);
    font-family: 'Baloo Da 2', cursive;
    font-weight: 700;
  }
  form{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    transition: all .3s ease-in-out;

  }

  form div{
    width: 54%;
  }

  form input  {
    border: solid 1px rgb(238, 217, 204);
    border-radius: .3rem;
    margin: .6rem;
    padding: .5rem;
    width: 100%;
  }

  .options{
    font-family: 'Baloo Da 2', cursive;
    font-weight: 700;
    cursor: pointer;
    margin: .2rem;
    margin-left: 1rem;
  }

  .options:hover{
    text-decoration: underline;
  }

  .log-msg-err, .log-msg-ok{
    border-radius: .5rem;
    padding: 1rem 2rem;
  }

  .log-msg-ok{
    background: rgba(0, 255, 0, 0.1);
    border: solid 1px rgb(42, 130, 58);
    color: rgb(42, 130, 58);
  }

  .log-msg-err{
    background: rgba(255, 0, 0, 0.1);
    color: rgb(170, 30, 30);
    border: solid 1px rgb(170, 30, 30);
  }

  form input:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }

  input[type='submit']{
    font-family: 'Baloo Da 2', cursive;
    background: rgb(255, 249, 240);
    font-weight: 700;
  }

  a, .form-btn{
    font-family: 'Baloo Da 2', cursive;
    background: rgb(255, 249, 240);
    font-weight: 700;
    text-align: center;
    padding: .5rem;
    margin: .2rem .6rem;
    border: solid 1px rgb(238, 217, 204);
    border-radius: .3rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  input[type='submit']:hover, a:hover{
    background: rgb(114, 47, 13);
    color: white;
  }

  label{
    display: flex;
    flex-direction: row;
  }

  label[for="email"],
  label[for="password"],
  label[for="repeated"]{
    margin-left: 4%;
  }

  label div{
    margin-left: .8rem;
    display: flex;
    flex-direction: column-reverse;
    font-size: 1.5rem;
    font-family: 'Baloo Da 2', cursive;
  }

  label img {
    width: 12%;
  }

  @media (max-width: 1280px){
    form div{
      width: 70vw;
    }
  }

  @media (max-width: 785px){
    h1{
      padding: 0rem 1rem;
    }
    form div{
      width: 80vw;
    }
    span{
      font-size: 1.2rem;
    }
  }


</style>