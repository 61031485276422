<template>
  <div>
    <px-loading id="px-loading" v-if="!isLoaded" :url="url" :index="loadingPhrase"></px-loading>

    <px-pictos v-show="isLoaded"
               @newPhrase="loadingPhrase++"
               @isLoaded="isLoaded = !isLoaded">
    </px-pictos>

  </div>
</template>

<script>

import PxPictos from "@/components/PxPictos";
import PxLoading from "@/components/PxLoading";

export default {
  name: "Home",
  components: {
    PxPictos,
    PxLoading,
  },

  data(){
    return{
      url: 'https://api.arasaac.org/api/',
      isLoaded: false,
      loadingPhrase: 1,

    }
  },

  created(){

  },
}
</script>

<style scoped>

</style>