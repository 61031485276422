<template>
  <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      width="185mm"
      height="52mm"
      viewBox="0 0 185 52"
      version="1.1"
      id="svg8"
      inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
      sodipodi:docname="logo.svg">
    <defs
        id="defs2" />
    <sodipodi:namedview
        id="base"
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1.0"
        inkscape:pageopacity="0.0"
        inkscape:pageshadow="2"
        inkscape:zoom="0.7"
        inkscape:cx="106.23257"
        inkscape:cy="545.52707"
        inkscape:document-units="mm"
        inkscape:current-layer="layer1"
        showgrid="false"
        inkscape:window-width="1920"
        inkscape:window-height="1023"
        inkscape:window-x="0"
        inkscape:window-y="0"
        inkscape:window-maximized="1"
        units="mm"
        width="209mm" />
    <metadata
        id="metadata5">
      <rdf:RDF>
        <cc:Work
            rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type
              rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
        inkscape:label="Capa 1"
        inkscape:groupmode="layer"
        id="layer1"
        transform="translate(0,-245)">
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.58333302px;line-height:1.25;font-family:Pacifico;-inkscape-font-specification:Pacifico;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
          x="33.675961"
          y="105.10048"
          id="text817"><tspan
         sodipodi:role="line"
         id="tspan815"
         x="33.675961"
         y="114.27652"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Pacifico;-inkscape-font-specification:Pacifico;stroke-width:0.26458332" /></text>
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.58333302px;line-height:1.25;font-family:zeyada;-inkscape-font-specification:zeyada;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
          x="39.498508"
          y="115.38243"
          id="text821"><tspan
         sodipodi:role="line"
         id="tspan819"
         x="39.498508"
         y="122.82978"
         style="stroke-width:0.26458332" /></text>
      <flowRoot
          xml:space="preserve"
          id="flowRoot823"
          style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:40px;font-family:Pacifico;-inkscape-font-specification:Pacifico;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none"
          transform="scale(0.26458333)"><flowRegion
         id="flowRegion825"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Pacifico;-inkscape-font-specification:Pacifico"><rect
           id="rect827"
           width="281.78571"
           height="152.5"
           x="77.5"
           y="395.01968"
           style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Pacifico;-inkscape-font-specification:Pacifico" /></flowRegion><flowPara
          id="flowPara829" /></flowRoot>    <text
        xml:space="preserve"
        style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:37.0968399px;line-height:1.25;font-family:Pacifico;-inkscape-font-specification:Pacifico;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.92742103"
        x="3.7413592"
        y="278.13715"
        id="text833"><tspan
         sodipodi:role="line"
         id="tspan831"
         x="3.7413592"
         y="278.13715"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Pacifico;-inkscape-font-specification:Pacifico;stroke-width:0.92742103">Picto</tspan></text>
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:37.0968399px;line-height:1.25;font-family:'Indie Flower';-inkscape-font-specification:'Indie Flower';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.92742103"
          x="81.909698"
          y="277.81284"
          id="text837"><tspan
         sodipodi:role="line"
         id="tspan835"
         x="81.909698"
         y="277.81284"
         style="stroke-width:0.92742103">Talking</tspan></text>
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.58333302px;line-height:1.25;font-family:Pacifico;-inkscape-font-specification:Pacifico;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
          x="43.447594"
          y="146.73405"
          id="text833-3"><tspan
         sodipodi:role="line"
         id="tspan831-6"
         x="43.447594"
         y="155.9101"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Pacifico;-inkscape-font-specification:Pacifico;stroke-width:0.26458332" /></text>
    </g>
  </svg>
</template>

<script>
export default {
name: "PxLogo"
}
</script>

<style scoped>

</style>