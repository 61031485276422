import Vue from 'vue'; // importar libreria vue de npm
import App from './App.vue'; //importar componente App.vue
import store from "./store";
import "@/assets/css/tailwind.css";
//start fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faBars,
  faHeart,
  faSearch,
  faPlay,
  faTrash,
  faAngleUp,
  faPlus,
  faSave, faAngleRight, faAngleLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from '@/router'

library.add(faUser, faBars, faHeart, faSearch, faPlay, faTrash, faAngleUp, faPlus, faSave, faAngleRight, faAngleLeft)

Vue.component('font-awesome-icon', FontAwesomeIcon)
//end fontawesome

Vue.config.productionTip = false

//instancia de Vue
//$mount reemplaza al elemento el
//render reemplaza el seteo de la aplicacion dentro del componente

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')
