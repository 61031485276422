<template>
<div class="container">
  <h1>Bienvenido, estas registrado como <b>{{$store.state.user === null || typeof $store.state.user === 'undefined' ? '' : $store.state.user.email}}</b></h1>

  <div v-if="false">
    <form v-for="(verb, index) in verbs" :key="verb._id" :id="verb._id" class="flex flex-col">
      <label :for="verb.verb.infinitive">{{index}} - {{verb.verb.infinitive}}</label>
      <input v-for="(value, person) in verb.verb.present" :key="person" type="text" :placeholder="person" class="m-2 p-2 border" @blur="updateVerb" :value="value">
    </form>
  </div>

  <section>
    <h2>Tienes <b>{{$store.state.user === null || typeof $store.state.user === 'undefined' ? '' : $store.state.user.favourites.length}}</b> pictogramas favoritos</h2>
    <h2>Tienes frases guardadas</h2>
    <button @click="endSession">Cerrar sesion</button>
  </section>
  <div class="router-container">
    <router-link class="router" to="/"><img src="https://api.arasaac.org/api/pictograms/30205" alt="Categorias">Busca los pictogramas en las categorias</router-link>
    <router-link class="router" to="/favoritos"><img src="https://api.arasaac.org/api/pictograms/4613" alt="Favoritos">Busca en tus pictogramas favoritos</router-link>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PxMe",

  data(){
    return{

    }
  },

  methods:{
    endSession: function (){
      if (localStorage.getItem('token')){
        this.$store.commit('setUser', null);
        localStorage.removeItem('token');
        this.$router.push('/login');
      }
    },

    updateVerb: function (){
      let id = event.target.parentNode.id;
      let value = event.target.value;
      let time = event.target.getAttribute('placeholder');

      let verb = {
        present:{

        }
      }

      verb.present[time] = value;

      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', localStorage.getItem('token'));

      let body = JSON.stringify({verb})

      fetch(`${this.$store.state.server}verbs/update/${id}`,{
        method: 'PATCH',
        headers,
        body,
        redirect: 'follow',
      })

    }
  },

  created() {

  },

  computed: {
    ...mapState(['verbs']),
  },


}
</script>

<style scoped>

  .container{
    font-family: 'Baloo Da 2', cursive;
  }

  h1{
    padding: 1.5rem 0 0 0;
    text-align: center;
    font-size: 2rem;
  }

  h2{
    font-size: 1.8rem;
  }

  button{
    padding: 1rem 3rem;
    background: rgb(255, 0, 0);
    color: #fff;
    border-radius: .3rem;
  }

  button:hover{
    background: rgb(119, 0, 0)
  }

  section{
    text-align: center;
  }

  section h2, section button{
    margin: .7rem 0;
  }

  .router-container{
    display: flex;
    justify-content: center;
  }

  .router{
    padding: 1rem;
    background: rgb(255, 249, 240);
    border: 1px solid rgb(238, 217, 204);
    border-radius: .3rem;
    margin: .5rem;
    font-weight: 700;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .router > img{
    max-width: 50%;
  }

  .router:hover{
    background: rgb(114, 47, 13);
    color: #fff;
  }


</style>