<template>
  <section class="h-0">
    <div class="contenedor">

      <h1 class="text-4xl text-center pt-8 pb-8">Pictogramas Favoritos</h1>
      <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer hidden heart" icon="heart"/>
      <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer hidden plus" icon="plus"/>

      <div v-for="(subcategories, categories) in flag ? categories : favForShow"
           v-bind:key="categories+1"
           :class="`subcategories-container flex flex-wrap justify-start hidden ${categories}`">

        <div v-for="(subcategorie, index) in subcategories.en"
             :key="index"
             :id="typeof subcategorie._id === 'undefined' ? 'subcategorie-' + (subcategorie.includes(' ') ? subcategorie.replace(/ /g, '-') : subcategorie) : subcategorie._id + '-' + categories"
             :class="(typeof subcategorie.keywords === 'undefined') ?
            'subcategorie hidden' + ' ' + (subcategorie.includes(' ') ? subcategorie.replace(/ /g, '-') : subcategorie) : 'pictogram hidden' + ' ' +
            (subcategorie.categories.toString().includes(' ') || subcategorie.categories.toString().includes(',') ? subcategorie.categories.toString().replace(/ /g, '-').replace(/,/g ,' ') : subcategorie.categories.toString())"
             @click="
            talkingPictos((typeof subcategorie.keywords === 'undefined') ? '' : subcategorie.keywords[0].keyword);
            favourite();
            addToConstructor();">
          {{(typeof subcategorie.keywords === 'undefined') ? subcategories.es[index] : ''}}
        </div>

        <div class="picto-container flex flex-wrap justify-center center" @click="tenFavMore"></div>

      </div>

      <div :class="`categories-container ${categories}`"
           v-for="(object, categories) in flag ? categories : favForShow"
           v-bind:key="categories"
           @click="extractPictos(); toggleSubcategories(); talkingCategories(categories); showFavPictos();">
        <img  :src="`${$store.state.arasaac}pictograms/${isNaN(object.idPic) ? 16697 : object.idPic}`"
              :alt="categories"
              class="picto-categorie">
        <span>{{categories.includes('-') ? categories.replace(/-/g, ' ') : categories}}</span>
      </div>

    </div>

  </section>


</template>

<script>

import {mapState} from "vuex";

export default {
  name: "PxPictoFAv",

  data(){
    return{
      subcategoriesContainers: [],
      favForShow: {},
      isLoaded: false,
      pictoCategories: [],
      pictosInCategorie: [],
      flag: true,
    }
  },

  props:{

  },

  created() {
    if (this.isLoaded)
      this.isLoaded = !this.isLoaded;
  },

  mounted() {
    if (this.categories !== null && this.pictos !== null){
      this.arrangeFavs();
      setTimeout(() =>{
        this.arrangeContainers();
      }, 300);
      this.isLoaded = !this.isLoaded;
    }
  },

  methods:{

    talkingPictos: function(toSay){

      const setConfigToTalk = function (voices){
        var utterance = new SpeechSynthesisUtterance();

        let spanish;

        if (voices.length > 0){
          spanish = voices.filter(response => response.lang.includes('es'));

          utterance.volume = 1;
          utterance.pitch = 1;
          utterance.rate = .9;
          utterance.voice = spanish[0]
          utterance.voiceURI = spanish[0].voiceURI;
          utterance.lang = spanish[0].lang
          utterance.text = toSay;
          speechSynthesis.cancel();
          speechSynthesis.speak(utterance);
        }
      }

      if (this.voices.length === 0){
        this.$store.dispatch('getVoices')
        .then(() =>{
          setConfigToTalk(this.voices);
        })
      }else{
        setConfigToTalk(this.voices);
      }

    },

    talkingCategories : function (toSay){

      const setConfigToTalk = function (voices){
        let utterance = new SpeechSynthesisUtterance();

        let spanish;

        if (voices.length > 0){
          spanish = voices.filter(response => response.lang.includes('es') || response.lang.includes('ESP'));
          utterance.volume = 1;
          utterance.pitch = 1;
          utterance.rate = .9;
          utterance.voice = spanish[0]
          utterance.voiceURI = spanish[0].voiceURI;
          utterance.lang = spanish[0].lang
          utterance.text = toSay;
        }

        let target = event.target.classList.contains('picto-categorie') ? event.target.parentNode : event.target;

        if (target.classList.contains('categories-container') && !target.classList.contains('told')){
          speechSynthesis.cancel();
          speechSynthesis.speak(utterance);
          target.classList.add('told');
        }else if (target.classList.contains('categories-container') && target.classList.contains('told')){
          target.classList.remove('told');
        }
      }

      if (this.voices.length === 0){
        this.$store.dispatch('getVoices')
        .then(()=>{
          setConfigToTalk(this.voices);
        })
      }else{
        setConfigToTalk(this.voices);
      }

    },

    toggleSubcategories: function(){
      let childNodes = event.target.classList.contains('picto-categorie') ? event.target.parentNode.childNodes : event.target.childNodes;
      let subcategoriesContainer;
      let pictoContainer;

      if (typeof childNodes[3] !== 'undefined' && (childNodes[3].nodeName === 'DIV' && childNodes[3].classList.contains('picto-container'))){
        pictoContainer = childNodes[3];
      }

      for (let node in childNodes){
        if (typeof childNodes[node] === 'object' && typeof childNodes[node].classList !=='undefined'){

          if (childNodes[node].nodeName === 'DIV'
              && childNodes[node].classList.contains('subcategories-container')
              && !childNodes[node].classList.contains('hidden')
          ){
            subcategoriesContainer = childNodes[node];
            subcategoriesContainer.classList.add('hidden');
            pictoContainer.classList.add('hidden');

          }else if(childNodes[node].nodeName === 'DIV'
              && childNodes[node].classList.contains('subcategories-container')
              && childNodes[node].classList.contains('hidden')
          ){
            subcategoriesContainer = childNodes[node];
            subcategoriesContainer.classList.remove('hidden');
            pictoContainer.classList.remove('hidden');
          }

          if (typeof childNodes[node] === 'object'
              && (childNodes[node].nodeName === 'DIV' && childNodes[node].classList.contains('subcategories-container')))
            childNodes = childNodes[node].childNodes;
        }
      }

      //if click in a part of categories container dont hide the subcategories
      for (var i = 0; i < childNodes.length; i++){
        if ((event.target.classList.contains('categories-container')
            || (event.target.parentNode.classList.contains('categories-container')
                && event.target.classList.contains('picto-categorie')))
            && childNodes[i].nodeName === 'DIV'
            && !childNodes[i].classList.contains('hidden')
            && childNodes[i].classList.contains('subcategorie')
        ){
          childNodes[i].classList.add('hidden');
        }else if ((event.target.classList.contains('categories-container')
            || (event.target.parentNode.classList.contains('categories-container')
                && event.target.classList.contains('picto-categorie')))
            && childNodes[i].nodeName === 'DIV'
            && childNodes[i].classList.contains('hidden')
            && childNodes[i].classList.contains('subcategorie')
        ){
          childNodes[i].classList.remove('hidden');
        }
      }


    },

    extractPictos: function (){

      let target = event.target.classList.contains('picto-categorie') ? event.target.parentNode : event.target;
      let childNodes = target.childNodes;
      let subcategoriesContainer;
      let pictoContainer;

      if (target.classList.contains('categories-container') && target.nodeName === 'DIV'){
        for (let childNode in childNodes){
          if (typeof childNodes[childNode] === 'object'){

            if (childNodes[childNode].nodeName === 'DIV' && childNodes[childNode].classList.contains('subcategories-container'))
              subcategoriesContainer = childNodes[childNode];

            if (childNodes[childNode].nodeName === 'DIV' && childNodes[childNode].classList.contains('picto-container'))
              pictoContainer = childNodes[childNode];
          }
        }
        if (typeof subcategoriesContainer !== 'undefined'){
          for (let element in subcategoriesContainer.childNodes){
            if (typeof subcategoriesContainer.childNodes[element] === 'object' && typeof subcategoriesContainer.childNodes[element] !== 'undefined' && subcategoriesContainer.childNodes[element].classList.contains('pictogram')){
              while(typeof subcategoriesContainer.childNodes[element] !== 'undefined' && subcategoriesContainer.childNodes[element].classList.contains('pictogram')){
                pictoContainer.appendChild(subcategoriesContainer.childNodes[element]);
              }
            }
          }
          subcategoriesContainer.remove();
        }
      }

    },

    arrangeFavs: function (){
        let domPictograms = document.getElementsByClassName('pictogram');

        for (let picto in this.pictos){

          if (localStorage.getItem(this.pictos[picto]._id) !== null){
            for (var i = 0; i < domPictograms.length; i++){
              if (parseInt(domPictograms[i].id.slice(0, domPictograms[i].id.indexOf('-'))) === this.pictos[picto]._id){
                let myCategorie = domPictograms[i].id.slice(domPictograms[i].id.indexOf('-') + 1);

                this.favForShow[myCategorie] = this.emptyCategories[myCategorie];

                if (!this.favForShow[myCategorie].en.includes(this.pictos[picto])){
                  this.favForShow[myCategorie].en.push(this.pictos[picto])
                }
              }
            }
          }
        }

        this.flag = !this.flag;
    },

    arrangeContainers: function (){

      for (let container in document.getElementsByClassName('subcategories-container')){
        this.subcategoriesContainers.push(document.getElementsByClassName('subcategories-container')[container]);
      }

      for (let container in document.getElementsByClassName('categories-container')){
        for (let i = 0; i < this.subcategoriesContainers.length; i++){
          for (let categorie in this.favForShow){
            if (typeof document.getElementsByClassName('categories-container')[container].classList !== 'undefined'
                && document.getElementsByClassName('categories-container')[container].classList.contains(categorie)
                && typeof this.subcategoriesContainers[i].classList !== 'undefined'
                && this.subcategoriesContainers[i].classList.contains(categorie)
            ){
              document.getElementsByClassName('categories-container')[container].appendChild(this.subcategoriesContainers[i]);
              document.getElementsByClassName('categories-container')[container].appendChild(document.getElementsByClassName('picto-container')[0]);
            }
          }
        }
      }

    },

    tenMore: function (){
      let target = event.target;

      if (target.nodeName === 'BUTTON' && target.classList.contains('tenMore')){
        let pictoContainer = target.parentNode.childNodes;
        let categoriesContainer = target.parentNode.parentNode;
        let subcategoriesContainer;
        let subcategoriesSelected = [];
        let dataV = target.dataset;

        if (dataV){
          for (let dataAttr in dataV){
            dataV = (`data-${dataAttr}`);
          }
        }

        for (let categorie in categoriesContainer.childNodes){
          if (categoriesContainer.childNodes[categorie].nodeName === 'DIV' && categoriesContainer.childNodes[categorie].classList.contains('subcategories-container')){
            subcategoriesContainer = categoriesContainer.childNodes[categorie];
          }
        }

        for (let subcategorie in subcategoriesContainer.childNodes){
          if (typeof subcategoriesContainer.childNodes[subcategorie].classList !== 'undefined' && subcategoriesContainer.childNodes[subcategorie].classList.contains('subselected')){
            subcategoriesSelected.push(subcategoriesContainer.childNodes[subcategorie].classList[1]);
          }
        }

        let stop = 0;
        let thereAreHidden = true;

        for (let picto in pictoContainer){
          if (typeof pictoContainer[picto] === 'object' && typeof typeof pictoContainer[picto].classList !== 'undefined'){

            for (let i = 0; i < subcategoriesSelected.length; i++){
              if (pictoContainer[picto].classList.contains(subcategoriesSelected[i].includes(' ') ? subcategoriesSelected[i].replace(/ /g, '-') : subcategoriesSelected[i]) && pictoContainer[picto].classList.contains('hidden') && pictoContainer[picto].classList.contains('pictogram')){
                stop++;
                pictoContainer[picto].classList.remove('hidden');

                if (pictoContainer[picto].childNodes.length < 2){
                  let id = pictoContainer[picto].id;
                  let idPicto = id.slice(0, id.indexOf('-'));

                  let heart = document.getElementsByClassName('heart');
                  let heartClone = heart[0].cloneNode(true);
                  let img = document.createElement('img');

                  heartClone.classList.remove('hidden');


                  img.setAttribute(dataV, '');
                  img.setAttribute('src', this.$store.state.arasaac+'pictograms/'+idPicto);
                  pictoContainer[picto].append(img);
                  pictoContainer[picto].append(heartClone);
                }
              }

              if (pictoContainer[picto].classList.contains('pictogram')
                  && pictoContainer[picto].classList.contains('hidden')
                  && pictoContainer[picto].classList.contains(subcategoriesSelected[i].includes(' ') ? subcategoriesSelected[i].replace(/ /g, '-') : subcategoriesSelected[i])
              ){
                thereAreHidden = true;
              }else if (pictoContainer[picto].classList.contains('pictogram')
                  && !pictoContainer[picto].classList.contains('hidden')
                  && pictoContainer[picto].classList.contains(subcategoriesSelected[i].includes(' ') ? subcategoriesSelected[i].replace(/ /g, '-') : subcategoriesSelected[i])
              ){
                thereAreHidden = false;
              }

            }

            if (!thereAreHidden && pictoContainer[picto].nodeName === 'BUTTON')
              pictoContainer[picto].remove();
          }

          if (stop === 10){
            break;
          }
        }

      }
    },

    favourite: function (){
      if (event.target.nodeName === 'path' && event.target.parentNode.getAttribute('data-icon') === 'heart'){
        let id = event.target.parentNode.parentNode.id;
        let svg = event.target.parentNode;
        svg.classList.contains('hearted') ? svg.classList.remove('hearted') : svg.classList.add('hearted');

        if (localStorage.getItem(id.slice(0, id.indexOf('-'))) === null){
          localStorage.setItem(id.slice(0, id.indexOf('-')), id);
        }else{
          localStorage.removeItem(id.slice(0, id.indexOf('-')));
        }

      }

      if (event.target.nodeName === 'svg' && event.target.getAttribute('data-icon') === 'heart'){
        let id = event.target.parentNode.id;
        let svg = event.target;
        svg.classList.contains('hearted') ? svg.classList.remove('hearted') : svg.classList.add('hearted');

        if (localStorage.getItem(id.slice(0, id.indexOf('-'))) === null){
          localStorage.setItem(id.slice(0, id.indexOf('-')), id);
        }else{
          localStorage.removeItem(id.slice(0, id.indexOf('-')));
        }


      }

    },

    addToConstructor: function(){
      if (event.target.nodeName === 'path' && event.target.parentNode.getAttribute('data-icon') === 'plus'){
        let svg = event.target.parentNode;
        let id = event.target.parentNode.parentNode.id;

        svg.classList.contains('plused') ? svg.classList.remove('plused') : svg.classList.add('plused');
        svg.classList.contains('plused') ? this.$store.dispatch('appendPictoToCustomPhrase', id) : this.$store.dispatch('removePictoToCustomPhrase', id);

      }

      if (event.target.nodeName === 'svg' && event.target.getAttribute('data-icon') === 'plus'){
        let svg = event.target;
        let id = event.target.parentNode.id;

        svg.classList.contains('plused') ? svg.classList.remove('plused') : svg.classList.add('plused');
        svg.classList.contains('plused') ? this.$store.dispatch('appendPictoToCustomPhrase', id) : this.$store.dispatch('removePictoToCustomPhrase', id);

      }

    },

    showFavPictos: function (){

      let target = event.target.nodeName === 'DIV' ? event.target : event.target.parentNode;
      let isButton = false;
      let hasHidden = true;
      let pictoContainer;

      if (target.nodeName === 'DIV' || target.nodeName === 'IMG' || target.nodeName === 'SPAN'){
        for (let node in target.childNodes){
          if (typeof target.childNodes[node].classList !== 'undefined' && target.childNodes[node].classList.contains('picto-container')){
            pictoContainer = target.childNodes[node];
          }
        }
      }

      if (typeof pictoContainer !== "undefined"){
        let i = 0;

        for (let picto in pictoContainer.childNodes){
          if (typeof pictoContainer.childNodes[picto].classList !== 'undefined' && !pictoContainer.childNodes[picto].classList.contains('hidden'))
            hasHidden = false;
        }

        for(let picto in pictoContainer.childNodes){
          isButton = pictoContainer.childNodes[picto].nodeName === 'BUTTON' ? true : false;

          if (typeof pictoContainer.childNodes[picto].classList !== 'undefined' && pictoContainer.childNodes[picto].classList.contains('hidden') && hasHidden) {
            pictoContainer.childNodes[picto].classList.remove('hidden');
            i++;
          }else if(typeof pictoContainer.childNodes[picto].classList !== 'undefined' && !pictoContainer.childNodes[picto].classList.contains('hidden') && !hasHidden){
            pictoContainer.childNodes[picto].classList.add('hidden');
          }

          //add picto img and fav heart
          if (typeof pictoContainer.childNodes[picto].childNodes !== 'undefined' && pictoContainer.childNodes[picto].nodeName === 'DIV' && pictoContainer.childNodes[picto].childNodes.length < 2){
            this.appendPicto(pictoContainer.childNodes[picto]);
          }

          if (i === 10 && !isButton){
            this.appendTenMoreButton(pictoContainer);
            break;
          }
        }
      }
    },

    appendPicto : function (element){
      let dataV = element.dataset;

      if (dataV){
        for (let dataAttr in dataV){
          dataV = (`data-${dataAttr}`);
        }
      }

      let id = element.id;

      let idPicto = id.slice(0, id.indexOf('-'));

      let heart = document.getElementsByClassName('heart');
      let plus = document.getElementsByClassName('plus');
      let heartClone = heart[0].cloneNode(true);
      let plusClone = plus[0].cloneNode(true);
      let img = document.createElement('img');

      if (localStorage.getItem(idPicto) !== null){
        heartClone.classList.add('hearted');
      }

      heartClone.classList.remove('hidden');
      plusClone.classList.remove('hidden');

      img.setAttribute(dataV, '');
      img.setAttribute('src', this.$store.state.arasaac+'pictograms/'+idPicto);
      element.append(img);
      element.append(heartClone);
      element.append(plusClone);
    },

    appendTenMoreButton : function (element){
      let dataV = element.dataset;

      if (dataV){
        for (let dataAttr in dataV){
          dataV = (`data-${dataAttr}`);
        }
      }

      let button = document.createElement('button');
      button.innerText = '+ 10';
      button.setAttribute(dataV, '');
      button.classList.add('tenMore');
      element.appendChild(button);
    },

    tenFavMore: function (){
      let target = event.target;
      let dataV = target.dataset;
      let hasHidden = true;
      let i = 0;

      if (dataV){
        for (let dataAttr in dataV){
          dataV = (`data-${dataAttr}`);
        }
      }

      if (target.nodeName === 'BUTTON'){
        let pictoContainer = target.parentNode;

        for (let picto in pictoContainer.childNodes){
          if (typeof pictoContainer.childNodes[picto].classList !== 'undefined' && !pictoContainer.childNodes[picto].classList.contains('hidden'))
            hasHidden = false;
        }

        for (let picto in pictoContainer.childNodes){

          if (typeof pictoContainer.childNodes[picto].classList !== 'undefined' && pictoContainer.childNodes[picto].classList.contains('hidden')){
            if (pictoContainer.childNodes[picto].nodeName === 'DIV' && pictoContainer.childNodes[picto].childNodes.length < 2){
              let id = pictoContainer.childNodes[picto].id;
              let idPicto = id.slice(0, id.indexOf('-'));

              let heart = document.getElementsByClassName('heart');
              let heartClone = heart[0].cloneNode(true);
              let img = document.createElement('img');

              if (localStorage.getItem(idPicto) !== null){
                heartClone.classList.add('hearted');
              }

              heartClone.classList.remove('hidden');

              img.setAttribute(dataV, '');
              img.setAttribute('src', this.$store.state.arasaac+'pictograms/'+idPicto);
              pictoContainer.childNodes[picto].append(img);
              pictoContainer.childNodes[picto].append(heartClone);
            }
            pictoContainer.childNodes[picto].classList.remove('hidden');
            i++
          }else{
            if (!hasHidden){
              target.remove();
            }
          }
          if (i === 10)
            break
        }

      }

    },

  },

  computed:{

      ...mapState(['categories', 'pictos', 'emptyCategories', 'voices']),

  },

  watch: {
    isLoaded: function (){
      this.$emit('isLoaded');
    },

    pictos: function (){
      this.$emit('newPhrase');
    },

    categories: function (){
      setTimeout(()=>{
        this.arrangeFavs();
        setTimeout(()=>{
          this.arrangeContainers();
          this.isLoaded = !this.isLoaded;
        },100);
      }, 100);
    },

  },

}
</script>

<style scoped>

.contenedor{
  width: 65%;
  margin: 0 auto;
}
h1{
  color: rgb(114, 47, 13);
  font-family: 'Baloo Da 2', cursive;
  font-weight: 700;
}

button, .tenMore{
  padding: 1rem;
  background: #fff;
  border: 1px solid black;
  border-radius: .5rem;
}

.pictogram{
  width: 18%;
  border-radius: .5rem;
  border: solid 5px #000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  margin: 1rem .5rem;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: .3rem;
}

.pictogram img{
  border: solid 1px #9B9B9B;
}

.tenMore{
  width: 15%;
  margin: 1rem .5rem;
  font-size: 2.5rem;
  font-family: 'Baloo Da 2', cursive;
}

.categorie{
  text-transform: capitalize;
}

.categories{
  padding: .3rem;
}

.pictoContainer{
  cursor: auto;
  display: flex;
  flex-wrap: wrap;
}

.subcategorie{
  background: rgb(255, 249, 240);
  padding: .5rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin: .2rem;
  text-transform: capitalize;
}

.subselected{
  background: rgb(114, 47, 13);
  color: white;
}

.categories-container{
  border: solid 1px rgb(238, 217, 204);
  border-radius: .5rem;
  margin: 1.5rem 0rem;
  padding: 2rem;
  background: rgb(255, 249, 240);
  transition: all .3s ease-in-out;
  display: grid;
  grid-template-columns: 50% 50%;
}

.categories-container span{
  text-align: center;
  font-size: 5rem;
  text-transform: capitalize;
  font-family: 'Baloo Da 2', cursive;
  place-self: center;
  font-weight: bold;
  margin-right: 3rem;
}

.subcategories-container{
  margin-top: 1rem;
}

.subcategories-container, .picto-container{
  grid-column-start: 1;
  grid-column-end: 3;
}

.subcategories-container, .picto-container{
  transition: all .2s ease-in-out;
  font-size: 1rem;
}

.picto-categorie{
  width: 40%;
}

.heart, .plus{
  margin: .5rem;
}

.hearted{
  color: #FF0000
}

.plused{
  color: #19dd19;
}

@media (max-width: 1420px) {
  .categories-container{
    padding: .5rem 1.5rem;
  }
  .categories-container span{
    font-size: 3.8rem;
  }
}

@media  (max-width: 1346px) {
  .pictogram, .tenMore{
    width: 22%;
  }
}

@media (max-width: 1166px) {
  .categories-container span{
    font-size: 2.8rem;
  }
}

@media (max-width: 936px){
  .pictogram, .tenMore{
    width: 29%;
  }
}

@media (max-width: 896px) {
  .categories-container > img {
    width: 55%;
  }

  .contenedor{
    width: 100%;
    padding: 1rem;
  }
}

@media (min-width: 800px){
  .categories:hover{
    cursor: pointer;
    background: rgb(255, 249, 240);
  }

  .subcategorie:hover{
    background: rgb(114, 47, 13);
    cursor: pointer;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }

  .categories-container:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  }

  .heart:hover{
    color: #FF0000;
  }

  .plus:hover{
    color: #19dd19;
  }
}

@media (max-width: 535px) {

  .pictogram, .tenMore{
    width: 28%;
  }

  .pictogram{
    padding: .18rem;
  }

  .tenMore{
    font-size: 2rem;
  }

  .categories-container{
    padding: .8rem;
  }

  .categories-container span{
    font-size: 1.6rem;
    margin-right: 3rem;
  }

  h2{
    margin-top: 0rem;
  }
}

</style>