var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"h-0"},[_c('div',{staticClass:"contenedor"},[_c('h1',{staticClass:"text-4xl text-center pt-8 pb-8"},[_vm._v(_vm._s(_vm.token ? '¡Bienvenido!, selecciona los pictogramas en las categorias para organizar tus favoritos' : 'Pictogramas por categoría'))]),_c('font-awesome-icon',{staticClass:"lg:w-8 lg:h-8 w-6 h-6 cursor-pointer hidden heart",attrs:{"icon":"heart"}}),_c('font-awesome-icon',{staticClass:"lg:w-8 lg:h-8 w-6 h-6 cursor-pointer hidden plus",attrs:{"icon":"plus"}}),_c('form',{attrs:{"id":"search-box"},on:{"submit":function($event){$event.preventDefault();return _vm.searchPictos(_vm.search)}}},[_c('font-awesome-icon',{staticClass:"search-icon",attrs:{"icon":"search"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text","name":"search","id":"search","placeholder":"Busca el pictograma","autocomplete":"off"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})],1),(_vm.foundPictosForShow.length > 0)?_c('h2',{attrs:{"id":"found-info"}},[_vm._v("Se han encontrado "),_c('b',[_vm._v(_vm._s(_vm.foundPictos.length + _vm.foundPictosForShow.length)+" pictogramas")]),_vm._v(", se estan mostrando "+_vm._s(_vm.foundPictosForShow.length))]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.foundPictosForShow.length > 0),expression:"foundPictosForShow.length > 0"}],attrs:{"id":"found-container"},on:{"click":function($event){return _vm.showTenFound();}}},_vm._l((_vm.foundPictosForShow),function(picto){return _c('div',{key:("found-" + (picto._id)),staticClass:"pictogram",attrs:{"id":((picto._id) + "-search")},on:{"click":function($event){_vm.talkingPictos(picto.keywords[0].keyword);
           _vm.favourite();
           _vm.addToConstructor();}}},[_c('img',{attrs:{"src":((_vm.$store.state.arasaac) + "pictograms/" + (picto._id)),"alt":picto.keywords[0].keyword}}),_c('font-awesome-icon',{staticClass:"lg:w-8 lg:h-8 w-6 h-6 cursor-pointer heart",attrs:{"icon":"heart"}}),_c('font-awesome-icon',{staticClass:"lg:w-8 lg:h-8 w-6 h-6 cursor-pointer plus",attrs:{"icon":"plus"}})],1)}),0),_vm._l((_vm.categories),function(subcategories,categories){return _c('div',{key:categories+1,class:("subcategories-container flex flex-wrap justify-start hidden " + categories)},[_vm._l((subcategories.en),function(subcategorie,index){return _c('div',{key:index,class:(typeof subcategorie.keywords === 'undefined') ?
          'subcategorie hidden' + ' ' + (subcategorie.includes(' ') ? subcategorie.replace(/ /g, '-') : subcategorie) : 'pictogram hidden' + ' ' +
          (subcategorie.categories.toString().includes(' ') || subcategorie.categories.toString().includes(',') ? subcategorie.categories.toString().replace(/ /g, '-').replace(/,/g ,' ') : subcategorie.categories.toString()),attrs:{"id":typeof subcategorie._id === 'undefined' ? 'subcategorie-' + (subcategorie.includes(' ') ? subcategorie.replace(/ /g, '-') : subcategorie) : subcategorie._id + '-' + categories},on:{"click":function($event){_vm.filter();
          _vm.talkingPictos((typeof subcategorie.keywords === 'undefined') ? '' : subcategorie.keywords[0].keyword);
          _vm.favourite();
          _vm.addToConstructor();}}},[_vm._v(" "+_vm._s((typeof subcategorie.keywords === 'undefined') ? subcategories.es[index] : '')+" ")])}),_c('div',{staticClass:"picto-container flex flex-wrap justify-center center",on:{"click":_vm.tenMore}})],2)}),_vm._l((_vm.categories),function(object,categories){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.foundPictosForShow.length < 1),expression:"foundPictosForShow.length < 1"}],key:categories,class:("categories-container " + categories),on:{"click":function($event){_vm.extractPictos(); _vm.toggleSubcategories(); _vm.talkingCategories(categories);}}},[_c('img',{staticClass:"picto-categorie",attrs:{"src":((_vm.$store.state.arasaac) + "pictograms/" + (isNaN(object.idPic) ? 16697 : object.idPic)),"alt":categories}}),_c('span',[_vm._v(_vm._s(categories.includes('-') ? categories.replace(/-/g, ' ') : categories))])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }