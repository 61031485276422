<template>
  <px-login></px-login>
</template>

<script>
import PxLogin from "@/components/PxLogin";

export default {
  name: "Login",

  components:{
    PxLogin,
  },

  data(){
    return{

    }
  },

  created() {
    this.getAuthorization();
  },

  methods: {
    getAuthorization: async function (){
      if (localStorage.getItem('token'))
        this.$router.push('/me');
      else
        return false;
    }
  }

}
</script>

<style scoped>

</style>