<template>
  <nav class="lg:pl-6 lg:pr-8 lg:py-4 lg:pb-4 pl-4 pr-4 py-2 pb-2 flex justify-between items-center w-screen">
    <font-awesome-icon id="aside" class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer" icon="bars" @click="menuClicked()"/>
    <px-logo class="h-16 w-1/2 lg:w-1/6 cursor-pointer"></px-logo>
    <div @click="goTo"><font-awesome-icon class="1lg:w-8 lg:h-8 w-6 h-6 cursor-pointer" icon="user"/><div v-if="isConnected" class="circulo"></div></div>
  </nav>
</template>

<script>

import PxLogo from "@/components/PxLogo";
import {mapState} from "vuex";

export default {
  name: "PxNav",

  components: {
  PxLogo
  },

  data(){
    return{
      isConnected: false,
    }
  },

  methods:{

    menuClicked: function (){
      if (event.target.id === 'aside' || event.target.parentNode.id === 'aside'){
        this.$emit('menuClicked');
      }
    },

    goTo: function (){
      if (this.$store.state.user !== null){
        this.$router.push({name:'me'});
      }

      if (this.$store.state.user === null){
        this.$router.push({name:'login'});
      }

    }

  },

  computed: {
    ...mapState(['user']),
  },

  watch:{
    user: function (){
      this.isConnected = !this.isConnected;
    }
  }

}
</script>

<style scoped>

  nav{
    background: rgb(255, 249, 240);
    border-bottom: 1px solid rgb(238, 217, 204);
    z-index: 1;
  }

  .circulo {
    width: 10px;
    height: 10px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #5cb85c;
    position: relative;
    left: 80%;
    bottom: 11px;
  }

</style>