<template>
  <div>
    <px-loading id="px-loading" v-if="!isLoaded" :index="loadingPhrase"></px-loading>
    <px-picto-fAv v-show="isLoaded"
                  @newPhrase="loadingPhrase++"
                  @isLoaded="isLoaded = !isLoaded">
    </px-picto-fAv>
  </div>
</template>

<script>

import PxPictoFAv from "@/components/PxPictoFAv";
import PxLoading from "@/components/PxLoading";

export default {
  name: "Favs",

  components: {
    PxPictoFAv,
    PxLoading
  },

  data(){
    return{
      isLoaded: false,
      loadingPhrase: 1,
    }
  },

  created() {

  }
}
</script>

<style scoped>

</style>