<template>
  <section class="h-0">
    <div class="contenedor">

      <h1 class="text-4xl text-center pt-8 pb-8">{{ token  ? '¡Bienvenido!, selecciona los pictogramas en las categorias para organizar tus favoritos' : 'Pictogramas por categoría'}}</h1>
      <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer hidden heart" icon="heart"/>
      <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer hidden plus" icon="plus"/>

      <form id="search-box" @submit.prevent="searchPictos(search)">
        <font-awesome-icon class="search-icon" icon="search"/>
        <input type="text" name="search" id="search" placeholder="Busca el pictograma" v-model="search" autocomplete="off">
      </form>

      <h2 v-if="foundPictosForShow.length > 0" id="found-info">Se han encontrado <b>{{foundPictos.length + foundPictosForShow.length}} pictogramas</b>, se estan mostrando {{foundPictosForShow.length}}</h2>
      <div v-show="foundPictosForShow.length > 0" id="found-container" @click="showTenFound();">
        <div v-for="picto in foundPictosForShow"
             :key="`found-${picto._id}`"
             class="pictogram"
             :id="`${picto._id}-search`"
             @click="talkingPictos(picto.keywords[0].keyword);
             favourite();
             addToConstructor();">
          <img :src="`${$store.state.arasaac}pictograms/${picto._id}`" :alt="picto.keywords[0].keyword">
          <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer heart" icon="heart"/>
          <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer plus" icon="plus"/>
        </div>
      </div>

      <div v-for="(subcategories, categories) in categories"
           v-bind:key="categories+1"
            :class="`subcategories-container flex flex-wrap justify-start hidden ${categories}`">

        <div v-for="(subcategorie, index) in subcategories.en"
            :key="index"
            :id="typeof subcategorie._id === 'undefined' ? 'subcategorie-' + (subcategorie.includes(' ') ? subcategorie.replace(/ /g, '-') : subcategorie) : subcategorie._id + '-' + categories"
            :class="(typeof subcategorie.keywords === 'undefined') ?
            'subcategorie hidden' + ' ' + (subcategorie.includes(' ') ? subcategorie.replace(/ /g, '-') : subcategorie) : 'pictogram hidden' + ' ' +
            (subcategorie.categories.toString().includes(' ') || subcategorie.categories.toString().includes(',') ? subcategorie.categories.toString().replace(/ /g, '-').replace(/,/g ,' ') : subcategorie.categories.toString())"
            @click="filter();
            talkingPictos((typeof subcategorie.keywords === 'undefined') ? '' : subcategorie.keywords[0].keyword);
            favourite();
            addToConstructor();">
          {{(typeof subcategorie.keywords === 'undefined') ? subcategories.es[index] : ''}}
        </div>

        <div class="picto-container flex flex-wrap justify-center center" @click="tenMore"></div>

      </div>

      <div v-show="foundPictosForShow.length < 1"
           :class="`categories-container ${categories}`"
           v-for="(object, categories) in categories"
           v-bind:key="categories"
           @click="extractPictos(); toggleSubcategories(); talkingCategories(categories);">
        <img  :src="`${$store.state.arasaac}pictograms/${isNaN(object.idPic) ? 16697 : object.idPic}`"
              :alt="categories"
              class="picto-categorie">
        <span>{{categories.includes('-') ? categories.replace(/-/g, ' ') : categories}}</span>
      </div>

    </div>

  </section>


</template>

<script>

import {mapState} from 'vuex';

export default {
  name: "PxPictos",

  data(){
    return{
      subcategoriesContainers: [],
      isLoaded: false,
      token: localStorage.getItem('token'),
      user: {},
      search: '',
      foundPictos: [],
      foundPictosForShow: [],
    }
  },

  created() {
    if (this.isLoaded)
      this.isLoaded = !this.isLoaded;

    if (this.categories !== null && !this.isLoaded){
      this.isLoaded = !this.isLoaded;
    }


  },

  mounted() {
    if (this.categories !== null){
      this.arrangeContainers();
    }
  },

  methods:{

    arrangeContainers: function (){
      for (let container in document.getElementsByClassName('subcategories-container')){
        this.subcategoriesContainers.push(document.getElementsByClassName('subcategories-container')[container]);
      }

      for (let container in document.getElementsByClassName('categories-container')){
        for (let i = 0; i < this.subcategoriesContainers.length; i++){
          for (let categorie in this.categories){
            if (typeof document.getElementsByClassName('categories-container')[container].classList !== 'undefined'
                && document.getElementsByClassName('categories-container')[container].classList.contains(categorie)
                && typeof this.subcategoriesContainers[i].classList !== 'undefined'
                && this.subcategoriesContainers[i].classList.contains(categorie)
            ){
              document.getElementsByClassName('categories-container')[container].appendChild(this.subcategoriesContainers[i]);
              document.getElementsByClassName('categories-container')[container].appendChild(document.getElementsByClassName('picto-container')[0]);
            }
          }
        }
      }
    },

    dragPicto: function(){
      let pictogram = document.getElementsByClassName('pictogram');
      for (let picto of pictogram){
        picto.setAttribute('draggable', true);
      }
    },

    talkingPictos: function(toSay){

      const setConfigToTalk = function (voices){
        var utterance = new SpeechSynthesisUtterance();

        let spanish;

        if (voices.length > 0){
          spanish = voices.filter(response => response.lang.includes('es'));

          utterance.volume = 1;
          utterance.pitch = 1;
          utterance.rate = .9;
          utterance.voice = spanish[0];
          utterance.voiceURI = spanish[0].voiceURI;
          utterance.lang = spanish[0].lang;
          utterance.text = toSay;
          speechSynthesis.cancel();
          speechSynthesis.speak(utterance);
        }
      }

      if (this.voices.length === 0){
        this.$store.dispatch('getVoices')
            .then(() => {
              setConfigToTalk(this.voices);
            })
      }else{
        setConfigToTalk(this.voices);
      }


    },

    talkingCategories : function (toSay){

      const setConfigToTalk = function (voices = []){
        var utterance = new SpeechSynthesisUtterance();

        let spanish;

        if (voices.length > 0){
          spanish = voices.filter(response => response.lang.includes('es') || response.lang.includes('ESP'));
          utterance.volume = 1;
          utterance.pitch = 1;
          utterance.rate = .9;
          utterance.voice = spanish[0]
          utterance.voiceURI = spanish[0].voiceURI;
          utterance.lang = spanish[0].lang
          utterance.text = toSay;
        }

        let target = event.target.classList.contains('picto-categorie') ? event.target.parentNode : event.target;

        if (target.classList.contains('categories-container') && !target.classList.contains('told')){
          speechSynthesis.cancel();
          speechSynthesis.speak(utterance);
          target.classList.add('told');
        }else if (target.classList.contains('categories-container') && target.classList.contains('told')){
          target.classList.remove('told');
        }
      }

      if (this.voices.length === 0){
        this.$store.dispatch('getVoices')
            .then(() => {
              setConfigToTalk(this.voices);
            });
      }else{
        setConfigToTalk(this.voices);
      }


    },

    toggleSubcategories: function(){
      let childNodes = event.target.classList.contains('picto-categorie') ? event.target.parentNode.childNodes : event.target.childNodes;
      let subcategoriesContainer;
      let pictoContainer;

      if (typeof childNodes[3] !== 'undefined' && (childNodes[3].nodeName === 'DIV' && childNodes[3].classList.contains('picto-container'))){
        pictoContainer = childNodes[3];
      }

      for (let node in childNodes){
        if (typeof childNodes[node] === 'object' && typeof childNodes[node].classList !=='undefined'){

          if (childNodes[node].nodeName === 'DIV'
              && childNodes[node].classList.contains('subcategories-container')
              && !childNodes[node].classList.contains('hidden')
          ){
            subcategoriesContainer = childNodes[node];
            subcategoriesContainer.classList.add('hidden');
            pictoContainer.classList.add('hidden');

          }else if(childNodes[node].nodeName === 'DIV'
              && childNodes[node].classList.contains('subcategories-container')
              && childNodes[node].classList.contains('hidden')
          ){
            subcategoriesContainer = childNodes[node];
            subcategoriesContainer.classList.remove('hidden');
            pictoContainer.classList.remove('hidden');
          }

          if (typeof childNodes[node] === 'object'
              && (childNodes[node].nodeName === 'DIV' && childNodes[node].classList.contains('subcategories-container')))
            childNodes = childNodes[node].childNodes;
        }
      }

      //if click in a part of categories container dont hide the subcategories
      for (var i = 0; i < childNodes.length; i++){
        if ((event.target.classList.contains('categories-container')
            || (event.target.parentNode.classList.contains('categories-container')
            && event.target.classList.contains('picto-categorie')))
            && childNodes[i].nodeName === 'DIV'
            && !childNodes[i].classList.contains('hidden')
            && childNodes[i].classList.contains('subcategorie')
        ){
          childNodes[i].classList.add('hidden');
        }else if ((event.target.classList.contains('categories-container')
            || (event.target.parentNode.classList.contains('categories-container')
            && event.target.classList.contains('picto-categorie')))
            && childNodes[i].nodeName === 'DIV'
            && childNodes[i].classList.contains('hidden')
            && childNodes[i].classList.contains('subcategorie')
        ){
          childNodes[i].classList.remove('hidden');
        }
      }


    },

    extractPictos: function (){

      let target = event.target.classList.contains('picto-categorie') ? event.target.parentNode : event.target;
      let childNodes = target.childNodes;
      let subcategoriesContainer;
      let pictoContainer;

      if (target.classList.contains('categories-container') && target.nodeName === 'DIV'){
        for (let childNode in childNodes){
          if (typeof childNodes[childNode] === 'object'){

            if (childNodes[childNode].nodeName === 'DIV' && childNodes[childNode].classList.contains('subcategories-container'))
              subcategoriesContainer = childNodes[childNode];

            if (childNodes[childNode].nodeName === 'DIV' && childNodes[childNode].classList.contains('picto-container'))
              pictoContainer = childNodes[childNode];
          }
        }

        for (let element in subcategoriesContainer.childNodes){
          if (typeof subcategoriesContainer.childNodes[element] === 'object' && typeof subcategoriesContainer.childNodes[element] !== 'undefined' && subcategoriesContainer.childNodes[element].classList.contains('pictogram')){
            while(typeof subcategoriesContainer.childNodes[element] !== 'undefined' && subcategoriesContainer.childNodes[element].classList.contains('pictogram')){
              pictoContainer.appendChild(subcategoriesContainer.childNodes[element]);
            }
          }
        }
      }

    },

    filter: function(){

      let target = event.target;
      let parentNode = target.parentNode;
      let subcategorie = event.target.classList[1];
      let pictoContainer;
      let dataV = target.dataset;
      let isButton = false;
      let i = 0;
      let subcategoriesSelected = [];
      let removeButton = true;

      this.dragPicto();

      if (dataV){
        for (let dataAttr in dataV){
          dataV = (`data-${dataAttr}`);
        }
      }

      for (let node in parentNode.parentNode.childNodes){
        if (parentNode.parentNode.childNodes[node].nodeName === 'DIV' && parentNode.parentNode.childNodes[node].classList.contains('picto-container')){
          pictoContainer = parentNode.parentNode.childNodes[node];
        }
      }

      if(target.classList.contains('subcategorie')){
        let childNodes = pictoContainer.childNodes;

        target.classList.contains('subselected') ? target.classList.remove('subselected') : target.classList.add('subselected');

        for (let child in childNodes){
          if (childNodes[child].nodeName === 'BUTTON'){
            isButton = true;
          }
        }

        for (let child in childNodes){
          if (typeof childNodes[child].classList !== 'undefined' && childNodes[child].classList.contains(subcategorie)){

            if (childNodes[child].classList.contains('hidden') && target.classList.contains('subselected')){
              //append only one image
              if (childNodes[child].childNodes.length < 2){
                this.appendPicto(childNodes[child]);
              }

              //remove hidden class in element
              childNodes[child].classList.remove('hidden');

              i++;
              //add button
              if (i === 10 && !isButton){
                this.appendTenMoreButton(pictoContainer);
                break;
              }

            }else{
              childNodes[child].classList.add('hidden');
            }
          }

            for(let subcategorie in parentNode.childNodes){

              if (typeof parentNode.childNodes[subcategorie].classList !== 'undefined' && parentNode.childNodes[subcategorie].classList.contains('subselected') && !subcategoriesSelected.includes(parentNode.childNodes[subcategorie].textContent.trim().includes(' ') ? parentNode.childNodes[subcategorie].textContent.trim().replace(/ /g, '-') : parentNode.childNodes[subcategorie].textContent.trim())){
                subcategoriesSelected.push(parentNode.childNodes[subcategorie].textContent.trim().includes(' ') ? parentNode.childNodes[subcategorie].textContent.trim().replace(/ /g, '-') : parentNode.childNodes[subcategorie].textContent.trim());
              }

              for (let i = 0; i < subcategoriesSelected.length; i++){

                if (typeof childNodes[child].classList !== 'undefined'
                    && typeof parentNode.childNodes[subcategorie].classList !== 'undefined'
                    && childNodes[child].classList.contains(subcategoriesSelected[i])
                    && childNodes[child].classList.contains('hidden')
                ){
                  removeButton = false;
                }

              }

              if (childNodes[child].nodeName === 'BUTTON'){
                if (typeof parentNode.childNodes[subcategorie] !== 'undefined'
                    && typeof parentNode.childNodes[subcategorie].classList !== 'undefined'
                    && !parentNode.childNodes[subcategorie].classList.contains('subselected')
                    && removeButton
                ){
                  childNodes[child].remove();
                  break;
              }
            }
          }

        }

      }
    },

    tenMore: function (){
      let target = event.target;

      if (target.nodeName === 'BUTTON' && target.classList.contains('tenMore')){
        let pictoContainer = target.parentNode.childNodes;
        let categoriesContainer = target.parentNode.parentNode;
        let subcategoriesContainer;
        let subcategoriesSelected = [];
        let dataV = target.dataset;

        if (dataV){
          for (let dataAttr in dataV){
            dataV = (`data-${dataAttr}`);
          }
        }

        for (let categorie in categoriesContainer.childNodes){
          if (categoriesContainer.childNodes[categorie].nodeName === 'DIV' && categoriesContainer.childNodes[categorie].classList.contains('subcategories-container')){
            subcategoriesContainer = categoriesContainer.childNodes[categorie];
          }
        }

        for (let subcategorie in subcategoriesContainer.childNodes){
          if (typeof subcategoriesContainer.childNodes[subcategorie].classList !== 'undefined' && subcategoriesContainer.childNodes[subcategorie].classList.contains('subselected')){
            subcategoriesSelected.push(subcategoriesContainer.childNodes[subcategorie].classList[1]);
          }
        }

        let stop = 0;
        let thereAreHidden = true;

        for (let picto in pictoContainer){
          if (typeof pictoContainer[picto] === 'object' && typeof typeof pictoContainer[picto].classList !== 'undefined'){

            for (let i = 0; i < subcategoriesSelected.length; i++){
              if (pictoContainer[picto].classList.contains(subcategoriesSelected[i].includes(' ') ? subcategoriesSelected[i].replace(/ /g, '-') : subcategoriesSelected[i]) && pictoContainer[picto].classList.contains('hidden') && pictoContainer[picto].classList.contains('pictogram')){
                stop++;
                pictoContainer[picto].classList.remove('hidden');

                if (pictoContainer[picto].childNodes.length < 2){
                  this.appendPicto(pictoContainer[picto]);
                }
              }

              if (pictoContainer[picto].classList.contains('pictogram')
                  && pictoContainer[picto].classList.contains('hidden')
                  && pictoContainer[picto].classList.contains(subcategoriesSelected[i].includes(' ') ? subcategoriesSelected[i].replace(/ /g, '-') : subcategoriesSelected[i])
              ){
                thereAreHidden = true;
              }else if (pictoContainer[picto].classList.contains('pictogram')
                  && !pictoContainer[picto].classList.contains('hidden')
                  && pictoContainer[picto].classList.contains(subcategoriesSelected[i].includes(' ') ? subcategoriesSelected[i].replace(/ /g, '-') : subcategoriesSelected[i])
              ){
                thereAreHidden = false;
              }

            }

            if (!thereAreHidden && pictoContainer[picto].nodeName === 'BUTTON')
              pictoContainer[picto].remove();
          }

          if (stop === 10){
            break;
          }
        }

      }
    },

    favourite: function (){

        if (event.target.nodeName === 'path' && event.target.parentNode.getAttribute('data-icon') === 'heart'){
          let id = event.target.parentNode.parentNode.id;
          let svg = event.target.parentNode;
          svg.classList.contains('hearted') ? svg.classList.remove('hearted') : svg.classList.add('hearted');

          if (localStorage.getItem(id.slice(0, id.indexOf('-'))) === null){
            localStorage.setItem(id.slice(0, id.indexOf('-')), id);
          }else{
            localStorage.removeItem(id.slice(0, id.indexOf('-')));
          }
        }

        if (event.target.nodeName === 'svg' && event.target.getAttribute('data-icon') === 'heart'){
          let id = event.target.parentNode.id;
          let svg = event.target;
          svg.classList.contains('hearted') ? svg.classList.remove('hearted') : svg.classList.add('hearted');

          if (localStorage.getItem(id.slice(0, id.indexOf('-'))) === null){
            localStorage.setItem(id.slice(0, id.indexOf('-')), id);
          }else{
            localStorage.removeItem(id.slice(0, id.indexOf('-')));
          }
        }


    },

    addToConstructor: function(){
      if (event.target.nodeName === 'path' && event.target.parentNode.getAttribute('data-icon') === 'plus'){
        let svg = event.target.parentNode;
        let id = event.target.parentNode.parentNode.id;

        svg.classList.contains('plused') ? svg.classList.remove('plused') : svg.classList.add('plused');
        svg.classList.contains('plused') ? this.$store.dispatch('appendPictoToCustomPhrase', id) : this.$store.dispatch('removePictoToCustomPhrase', id);

      }

      if (event.target.nodeName === 'svg' && event.target.getAttribute('data-icon') === 'plus'){
        let svg = event.target;
        let id = event.target.parentNode.id;

        svg.classList.contains('plused') ? svg.classList.remove('plused') : svg.classList.add('plused');
        svg.classList.contains('plused') ? this.$store.dispatch('appendPictoToCustomPhrase', id) : this.$store.dispatch('removePictoToCustomPhrase', id);

      }

    },

    searchPictos: function (keyword){
      if (keyword === '') return false;
      let container = document.getElementById('found-container');
      this.foundPictos = [];
      this.foundPictosForShow = [];

      fetch(`${this.$store.state.arasaac}pictograms/es/search/${keyword}`)
        .then(response => response.json())
        .then(response => this.foundPictos = response)
        .then(() =>{
          for (let i = 0; i < 10; i++){
            if (typeof this.foundPictos[i] !== 'undefined'){
              let shiftPicto = this.foundPictos.shift();
              this.foundPictosForShow.push(shiftPicto);
            }
          }
        })
        .then(() => {
          for (var i = 0; i < container.childNodes.length; i++){
            if (typeof container.childNodes[i] !== 'undefined' && container.childNodes[i].classList.contains('tenMore')){
              container.childNodes[i].remove()
            }
          }
          if (this.foundPictos.length > 10 && this.foundPictosForShow.length === 10){
              this.appendTenMoreButton(container);
          }

        });

    },

    showTenFound: function (){
      let target = event.target;
      let parent = target.parentNode;
      if (target.classList.contains('tenMore') && this.foundPictos.length > 0){
        for (let i = 0; i < 10; i++){
          if (typeof this.foundPictos[i] !== 'undefined'){
            let shiftPicto = this.foundPictos.shift();
            this.foundPictosForShow.push(shiftPicto);
          }
        }

        target.remove();

        if (this.foundPictos.length > 0){
          setTimeout(()=>{
            this.appendTenMoreButton(parent);
          },50);
        }
      }
    },

    appendTenMoreButton : function (element){
      let dataV = element.dataset;

      if (dataV){
        for (let dataAttr in dataV){
          dataV = (`data-${dataAttr}`);
        }
      }

      let button = document.createElement('button');
      button.innerText = '+ 10';
      button.setAttribute(dataV, '');
      button.classList.add('tenMore');
      element.appendChild(button);
    },

    appendPicto : function (element){
      let dataV = element.dataset;

      if (dataV){
        for (let dataAttr in dataV){
          dataV = (`data-${dataAttr}`);
        }
      }

      let id = element.id;

      let idPicto = id.slice(0, id.indexOf('-'));

      let heart = document.getElementsByClassName('heart');
      let plus = document.getElementsByClassName('plus');
      let heartClone = heart[0].cloneNode(true);
      let plusClone = plus[0].cloneNode(true);
      let img = document.createElement('img');

      if (localStorage.getItem(idPicto) !== null){
        heartClone.classList.add('hearted');
      }

      heartClone.classList.remove('hidden');
      plusClone.classList.remove('hidden');

      img.setAttribute(dataV, '');
      img.setAttribute('src', this.$store.state.arasaac+'pictograms/'+idPicto);
      element.append(img);
      element.append(heartClone);
      element.append(plusClone);
    },

  },

  computed:{

    ...mapState(['categories', 'voices', 'customPhrase']),

  },

  watch: {
    isLoaded: function (){
        this.$emit('isLoaded');
    },

    search: function (){
      if (this.search.length === 0){
        this.foundPictos = [];
        this.foundPictosForShow = [];
      }
    },

    categories: function (){
      this.isLoaded = !this.isLoaded;

      setTimeout(()=>{
        this.arrangeContainers();
      },100);
    },

    customPhrase: function (){

    }

  },

}
</script>

<style scoped>

  .contenedor{
    width: 65%;
    margin: 0 auto;
  }
  h1{
    color: rgb(114, 47, 13);
    font-family: 'Baloo Da 2', cursive;
    font-weight: 700;
  }

  #search-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #search{
    width: 28vw;
    padding: .5rem;
    border-radius: .3rem;
    border: solid 1px rgb(238, 217, 204)
  }

  .search-icon{
    position: relative;
    left: 42%;
  }

  #found-info{
    margin: 1rem;
    text-align: center;
  }

  #found-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  button, .tenMore{
    padding: 1rem;
    background: #fff;
    border: 1px solid black;
    border-radius: .5rem;
  }

  .pictogram{
    width: 18%;
    border-radius: .5rem;
    border: solid 5px #000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    margin: 1rem .5rem;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: .3rem;
  }

  .pictogram img{
    border: solid 1px #9B9B9B;
  }

  .tenMore{
    width: 15%;
    margin: 1rem .5rem;
    font-size: 2.5rem;
    font-family: 'Baloo Da 2', cursive;
  }

  .categorie{
    text-transform: capitalize;
  }

  .categories{
    padding: .3rem;
  }

  .pictoContainer{
    cursor: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .subcategorie{
    background: rgb(255, 249, 240);
    padding: .5rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    margin: .2rem;
    text-transform: capitalize;
  }

  .subselected{
    background: rgb(114, 47, 13);
    color: white;
  }

  .categories-container{
    border: solid 1px rgb(238, 217, 204);
    border-radius: .5rem;
    margin: 1.5rem 0rem;
    padding: 2rem;
    background: rgb(255, 249, 240);
    transition: all .3s ease-in-out;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .categories-container span{
    text-align: center;
    font-size: 5rem;
    text-transform: capitalize;
    font-family: 'Baloo Da 2', cursive;
    place-self: center;
    font-weight: bold;
    margin-right: 3rem;
  }

  .subcategories-container{
    margin-top: 1rem;
  }

  .subcategories-container, .picto-container{
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .subcategories-container, .picto-container{
    transition: all .2s ease-in-out;
    font-size: 1rem;
  }

  .picto-categorie{
    width: 40%;
  }

  .heart, .plus{
    margin: .5rem 1rem;
  }

  .hearted{
    color: #FF0000
  }

  .plused{
    color: #19dd19;
  }

  @media (max-width: 1420px) {
    .categories-container{
      padding: .5rem 1.5rem;
    }
    .categories-container span{
      font-size: 3.8rem;
    }
  }

  @media  (max-width: 1346px) {
    .pictogram, .tenMore{
      width: 22%;
    }
  }

  @media (max-width: 1166px) {
    .categories-container span, h1{
      font-size: 2.8rem;
    }
  }

  @media (max-width: 936px){
    .pictogram, .tenMore{
      width: 29%;
    }
  }

  @media (max-width: 896px) {
    .categories-container > img {
      width: 55%;
    }

    .contenedor{
      width: 100%;
      padding: 1rem;
    }
  }

  @media (min-width: 800px){
    .categories:hover{
      cursor: pointer;
      background: rgb(255, 249, 240);
    }

    .subcategorie:hover{
      background: rgb(114, 47, 13);
      cursor: pointer;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }

    .categories-container:hover{
      box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }

    .heart:hover{
      color: #FF0000;
    }

    .plus:hover{
      color: #19dd19;
    }
  }

  @media (max-width: 535px) {

    .pictogram, .tenMore{
      width: 28%;
    }

    .pictogram{
      padding: .18rem;
    }

    .tenMore{
      font-size: 2rem;
    }

    .categories-container{
      padding: .8rem;
    }

    .categories-container span{
      font-size: 1.6rem;
      margin-right: 3rem;
    }

    h1{
      font-size: 2rem !important;
    }

    #search{
      width: 65vw;
      padding: .5rem;
      border-radius: .3rem;
    }

    .search-icon{
      position: relative;
      left: 67%;
    }

    .heart, .plus{
      margin: .5rem;
    }

  }

</style>