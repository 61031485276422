<template>
<div>
  <div class="icon-container" @click="toggleConstructor">
    <font-awesome-icon id="angle" class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer" icon="angle-up"/>
  </div>
  <div class="container">
    <h2>Construye tu frase</h2>
    <div id="options-container" @click="talkingPhrase($store.state.customPhrase)">
      <img class="time-option" :src="`${$store.state.arasaac}pictograms/9839`" alt="pasado">
      <img class="time-option time-selected" :src="`${$store.state.arasaac}pictograms/9848`" alt="presente">
      <img class="time-option" :src="`${$store.state.arasaac}pictograms/9829`" alt="futuro">
      <font-awesome-icon id="save" class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer" icon="save"/>
      <font-awesome-icon id="play" class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer" icon="play"/>
    </div>
    <div id="picto-container"
         @mousedown="mouseIsDown = true; setScroll(); $event.target.classList.add('grabbing')"
         @mouseleave="mouseIsDown = false; $event.target.classList.remove('grabbing');"
         @mouseup="mouseIsDown = false; $event.target.classList.remove('grabbing');"
         @mousemove.prevent="clickAndDragToScroll">
      <span v-for="picto in customPhrase" :key="picto._id" class="picto">
        <img :src="`${$store.state.arasaac}pictograms/${picto._id}`" :alt="picto.keywords[0].keyword">
        <div class="icon-cons-container" @click="removePictoFromConstructor">
          <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer angle-left" icon="angle-left"/>
          <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer trash" icon="trash"/>
          <font-awesome-icon class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer angle-right" icon="angle-right"/>
        </div>
      </span>
    </div>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PxConstructor",

  data(){
    return {
      mouseIsDown : false,
      scrollStartX: null,
      scrollLeft: null,
    }
  },

  methods: {
    toggleConstructor: function (){
/*      let target = event.target;
      if (target.nodeName === 'svg' || target.nodeName === 'path'){
        alert('hola');
      }*/
      this.$emit('clickConstructor');
    },

    setScroll: function(){
      let pictoContainer = document.getElementById('picto-container');
      this.scrollStartX = (event.pageX - pictoContainer.offsetLeft);
      this.scrollLeft = pictoContainer.scrollLeft;
    },

    clickAndDragToScroll: function (){
      let pictoContainer = document.getElementById('picto-container');
      if (!this.mouseIsDown) return;

      const x = (event.pageX - pictoContainer.offsetLeft);

      const walk = (x - this.scrollStartX);
      pictoContainer.scrollLeft = this.scrollLeft - walk;
    },

    talkingPhrase: function (customPhrase){
      let toSay = '';
      let pronouns = ['yo', 'tú', 'él', 'ella', 'nosotros', 'nosotras', 'vosotros', 'vosotras', 'ellos', 'ellas'];
      let box = [];

      for(let element of customPhrase){

        for (let i = 0; i < pronouns.length ; i++){
          if (element.keywords[0].keyword === pronouns[i]){
            box.push(element);
          }else if (!box.includes(element)){
            box.push(element);
          }
        }

        if (box.length > 1){
          for (let i = 0; i < box.length ; i++){
            if (typeof box[i].keywords !== 'undefined' && box[i].keywords[0].keyword === 'yo'){
              for (let verb of this.verbs){
                if (typeof box[i + 1].keywords !== 'undefined' && box[i + 1].keywords[0].keyword === verb.verb.infinitive){
                  box.splice(box.indexOf(box[i+1]),1, verb.verb.present.first)
                }
              }
            }else if (typeof box[i].keywords !== 'undefined' && box[i].keywords[0].keyword === 'tú'){
              for (let verb of this.verbs){
                if (typeof box[i + 1].keywords !== 'undefined' && box[i + 1].keywords[0].keyword === verb.verb.infinitive){
                  box.splice(box.indexOf(box[i+1]),1, verb.verb.present.second)
                }
              }
            }else if (typeof box[i].keywords !== 'undefined' && (box[i].keywords[0].keyword === 'él' || box[i].keywords[0].keyword === 'ella')){
              for (let verb of this.verbs){
                if (typeof box[i + 1].keywords !== 'undefined' && box[i + 1].keywords[0].keyword === verb.verb.infinitive){
                  box.splice(box.indexOf(box[i+1]),1, verb.verb.present.third)
                }
              }
            }else if (typeof box[i].keywords !== 'undefined' && (box[i].keywords[0].keyword === 'nosotros' || box[i].keywords[0].keyword === 'nosotras')){
              for (let verb of this.verbs){
                if (typeof box[i + 1].keywords !== 'undefined' && box[i + 1].keywords[0].keyword === verb.verb.infinitive){
                  box.splice(box.indexOf(box[i+1]),1, verb.verb.present.firsts)
                }
              }
            }else if (typeof box[i].keywords !== 'undefined' && (box[i].keywords[0].keyword === 'vosotros' || box[i].keywords[0].keyword === 'vosotras')){
              for (let verb of this.verbs){
                if (typeof box[i + 1].keywords !== 'undefined' && box[i + 1].keywords[0].keyword === verb.verb.infinitive){
                  box.splice(box.indexOf(box[i+1]),1, verb.verb.present.seconds)
                }
              }
            }else if (typeof box[i].keywords !== 'undefined' && (box[i].keywords[0].keyword === 'ellos' || box[i].keywords[0].keyword === 'ellas')){
              for (let verb of this.verbs){
                if (typeof box[i + 1].keywords !== 'undefined' && box[i + 1].keywords[0].keyword === verb.verb.infinitive){
                  box.splice(box.indexOf(box[i+1]),1, verb.verb.present.thirds)
                }
              }
            }
          }
        }

/*        const findPronoun = () => new Promise(resolve => {
            for (let pronoun of pronouns){
              if (pronoun === element.keywords[0].keyword) resolve(element);
            }
          });

        const findVerbs = () => new Promise(resolve => {
          for (let verb of this.verbs){
            if (verb.verb.infinitive === element.keywords[0].keyword) resolve(element);
          }
        });*/

/*        var foundPronoun;
        var conjugatedVerb;
        var infinitiveVerb;

        findPronoun()
            .then(pronoun => foundPronoun = pronoun)

        findVerbs()
            .then(r => {
              infinitiveVerb = r;
          if(customPhrase.length > 1 && customPhrase[customPhrase.indexOf(r) -1] === foundPronoun){
            let toConjugatedVerb = this.verbs.filter(verb => verb.verb.infinitive === r.keywords[0].keyword);

            if (foundPronoun.keywords[0].keyword === 'yo'){
              conjugatedVerb = toConjugatedVerb[0].verb.present.first;
              box.push(conjugatedVerb);
            }

            if (foundPronoun.keywords[0].keyword === 'tú'){
              conjugatedVerb = toConjugatedVerb[0].verb.present.second;
              box.push(conjugatedVerb);
            }

            if (foundPronoun.keywords[0].keyword === 'él' || foundPronoun.keywords[0].keyword === 'ella'){
              conjugatedVerb = toConjugatedVerb[0].verb.present.third;
              box.push(conjugatedVerb);
            }

            if (foundPronoun.keywords[0].keyword === 'nosotros' || foundPronoun.keywords[0].keyword === 'nosotras'){
              conjugatedVerb = toConjugatedVerb[0].verb.present.firsts;
              box.push(conjugatedVerb);
            }

            if (foundPronoun.keywords[0].keyword === 'vosotros' || foundPronoun.keywords[0].keyword === 'vosotras'){
              conjugatedVerb = toConjugatedVerb[0].verb.present.seconds;
              box.push(conjugatedVerb);
            }

            if (foundPronoun.keywords[0].keyword === 'ellos' || foundPronoun.keywords[0].keyword === 'ellas'){
              conjugatedVerb = toConjugatedVerb[0].verb.present.thirds;
              box.push(conjugatedVerb);
            }
          }
        })

        setTimeout(()=>{
          toSay = toSay + ' ' + (typeof infinitiveVerb === 'undefined' ? element.keywords[0].keyword :
                                 element.keywords[0].keyword !== infinitiveVerb.keywords[0].keyword ? element.keywords[0].keyword :
                                 typeof conjugatedVerb === 'undefined' ? element.keywords[0].keyword : conjugatedVerb);

        },100)*/

      }

      for (let element of box){
        if (typeof element.keywords !== 'undefined'){
          toSay = toSay + ' ' + element.keywords[0].keyword;
        }else{
          toSay = toSay + ' ' + element;
        }
      }

      setTimeout(() => {

        const setConfigToTalk = function (voices){
          var utterance = new SpeechSynthesisUtterance();

          let spanish;

          if (voices.length > 0){
            spanish = voices.filter(response => response.lang.includes('es'));

            utterance.volume = 1;
            utterance.pitch = 1;
            utterance.rate = .9;
            utterance.voice = spanish[0]
            utterance.voiceURI = spanish[0].voiceURI;
            utterance.lang = spanish[0].lang
            utterance.text = toSay.trim();
            speechSynthesis.cancel();
            speechSynthesis.speak(utterance);
          }
        }

        if (this.voices.length === 0){
          this.$store.dispatch('getVoices')
              .then(() =>{
                setConfigToTalk(this.voices);
              })
        }else{
          setConfigToTalk(this.voices);
        }

      },100)

    },

    removePictoFromConstructor: function (){

      if (event.target.nodeName === 'path' && event.target.parentNode.getAttribute('data-icon') === 'trash'){
        for (let picto in this.$store.state.customPhrase){
          if (this.$store.state.customPhrase[picto].keywords[0].keyword === event.target.parentNode.parentNode.parentNode.childNodes[0].getAttribute('alt')){
            this.$store.state.customPhrase.splice(picto, 1);
          }
        }
      }

      if (event.target.nodeName === 'svg' && event.target.getAttribute('data-icon') === 'heart'){
        for (let picto in this.$store.state.customPhrase){
          if (this.$store.state.customPhrase[picto].keywords[0].keyword === event.target.parentNode.parentNode.childNodes[0].getAttribute('alt')){
            this.$store.state.customPhrase.splice(picto, 1);
          }
        }
      }

    },
  },

  computed:{
    ...mapState(['customPhrase', 'voices', 'verbs']),
  }
}
</script>

<style scoped>
  h2{
    font-family: 'Baloo Da 2', cursive;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    margin-top: .5rem;
  }

  @keyframes angle-animation {
    50%{
      transform: translateY(10%);
    }
  }

  .container{
    overflow: hidden;
  }

  .icon-container{
    cursor: pointer;
    text-align: center;
    margin-bottom: -1rem;
    animation: angle-animation infinite linear;
  }

  .icon-cons-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: .3rem;
  }

  svg[data-icon="angle-up"]{
    animation: angle-animation infinite linear;
  }

  #angle{
    animation: angle-animation infinite linear;
  }

  #options-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px rgb(238, 217, 204);
    padding: .5rem;
  }

  .time-option{
    width: 5%;
    margin: 0rem .5rem;
    cursor: pointer;
    padding: .3rem;
  }

  #play{
    margin-left: .5rem;
  }

  #save{
    margin: 0 .5rem;
  }

  .time-option:hover, .time-selected{
    background: rgb(255, 243, 224);
    border: solid 1px rgb(238, 217, 204);
    border-radius: .3rem;
  }

  #picto-container{
    cursor: grab;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 1rem .5rem;
    overflow: scroll;
    scrollbar-width: none;  /* Firefox */
  }

  #picto-container::-webkit-scrollbar{
    display: none;
  }

  .grabbing{
    cursor: grabbing;
  }

  .picto{
    width: 13%;
    transition: linear .3s ease-in-out;
    border: solid 5px #000;
    margin: 0rem .5rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .3rem;
  }

  .picto img{
    border: solid 1px #9B9B9B;
  }

  .trash:hover{
    color: #FF0000;
  }

  .angle-left:hover, .angle-right:hover{
    color: rgb(114, 47, 13);
  }

  @media (max-width: 535px){
    .time-option{
      width: 11%;
    }

    .time-option:hover{
      background: none;
      border: none;
    }

    .picto{
      width: 28%;
    }

  }


</style>