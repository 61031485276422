<template>
  <px-me></px-me>
</template>

<script>
import PxMe from "@/components/PxMe";

export default {
  name: "Me",
  components: {
  PxMe,
  },
  data(){
    return{

    }
  },
  created() {
    this.getAuthorization();
  },

  methods:{

    getAuthorization: function (){
      if (this.$store.state.user === null && !localStorage.getItem('token')) this.$router.push('/login');
    }

  }
}
</script>

<style scoped>

</style>