import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        arasaac: 'https://api.arasaac.org/api/',
        server: window.location.host.includes('julio-stack.com') ? 'https://api.julio-stack.com/' : 'http://192.168.1.133:3000/',
        categories: null,
        emptyCategories: null,
        pictos: null,
        user: null,
        defaultPhrases: null,
        voices: null,
        verbs: null,
        customPhrase: [],
    },

    mutations:{
        setCategories( state, payload = {} ){
            return state.categories = payload;
        },

        setPictos(state, payload = {}){
            return state.pictos = payload;
        },

        setEmptyCategories( state, payload = {} ){
            return state.emptyCategories = payload;
        },

        setDefaultPhrases (state, payload = {}){
            return state.defaultPhrases = payload;
        },

        setUser( state, payload = {}){
            return state.user = payload;
        },

        setVoices(state, payload = []){
            return state.voices = payload;
        },

        setVerbs(state, payload = {}){
            return state.verbs = payload.body;
        },

        pushPictoToConstructor(state, payload = {}){
            return state.customPhrase.push(payload);
        },

        removePictoFromConnstructor(state, payload = {}){
            return state.customPhrase.splice(state.customPhrase.indexOf(payload), 1);
        }
    },

    actions:{
        getMyCategories: async function (context){
            try{
                const data = await fetch(`${context.state.server}pictograms`);
                const categories = await data.json();

                context.commit('setCategories', categories.body[0].myCategories);
            }catch (e){
                console.error('[getMyCategories] ', e)
            }

        },

        getPictoArasaac: async function(context){
            try{
                const data = await fetch(`${context.state.arasaac}pictograms/all/es`);
                const pictos = await data.json();

                context.commit('setPictos', pictos);
            }catch (e) {
                console.error('[getPictoArasaac] ', e)
            }
        },

        getEmptyCategories: async function(context){
            try{
                const data = await fetch(`${context.state.server}categories`);
                const emptyCategories = await data.json();

                context.commit('setEmptyCategories', emptyCategories.body[0].categories);
            }catch (e) {
                console.error('[getEmptyCategories] ', e)
            }
        },

        getDefaultPhrases: async function(context){
          try{
              const data = await fetch(`${context.state.server}phrases`);
              const defaultPhrases = await data.json();

              context.commit('setDefaultPhrases', defaultPhrases.body);
          }catch (e) {
              console.error('[getDefaultPhrases] ', e)
          }
        },

        getUser: async function(context, payload){
            try{
                let headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Authorization', payload);

                const data = await fetch(`${context.state.server}user`,{
                    method: 'GET',
                    headers,
                })

                const user = await data.json();

                context.commit('setUser', user.body);
            }catch (e) {
                console.error('[getUser] ', e)
            }
        },

        getVoices: async function(context){
            try {
                const voices = await speechSynthesis.getVoices();

                context.commit('setVoices', voices);
            }catch (e) {
                console.error('[getVoices] ', e) ;
            }
        },

        getVerbs: async function(context){
            try{
                const data = await fetch(`${context.state.server}verbs`);
                const verbs = await data.json();

                context.commit('setVerbs', verbs);
            }catch (e) {
             console.error('[getVerbs] ', e)   ;
            }
        },

        appendPictoToCustomPhrase: function(context, payload){
            let id = payload.slice(0, payload.indexOf('-'));
            let picto = context.state.pictos.filter(picto => picto._id === parseInt(id));

            context.commit('pushPictoToConstructor', picto[0]);
        },

        removePictoToCustomPhrase: function (context, payload){
            let id = payload.slice(0, payload.indexOf('-'));
            let picto = context.state.customPhrase.filter(picto => picto._id === parseInt(id));

            context.commit('removePictoFromConnstructor', picto[0]);
        }
    }

})

export default store;