<template>
  <aside>
    <ul>
      <li><img :src="`${this.$store.state.arasaac}pictograms/30205`" alt="Categorias" @click="getCategoriesClick(); goToCategories();">Categorias</li>
      <li><img :src="`${this.$store.state.arasaac}pictograms/4613`" alt="Corazon" @click="getFavsClick(); goToFavs();"> Favoritos</li>
      <li><img :src="`${this.$store.state.arasaac}pictograms/27768`" alt="Frases predefinidas" @click="getPhrasesClick() ;goToPhrase();">Frases predefinidas</li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "PxAside",

  created() {

  },

  data(){
    return{

    }
  },

  methods:{
    getFavsClick: function (){
      this.$emit('getFavsClick');
    },

    getCategoriesClick: function (){
      this.$emit('getCategoriesClick');
    },

    getPhrasesClick: function (){
      this.$emit('getPhrasesClick');
    },

    goToCategories: function (){
      this.$router.push({name: 'home'})
    },

    goToFavs: function (){
      this.$router.push({name: 'favourites'})
    },

    goToPhrase: function (){
      this.$router.push({name: 'phrase'})
    }

  },

  watch:{
    categories:function (){
      this.myCategories = this.categories;
    },

    pictograms: function (){
      this.pictos = this.pictograms;
    }
  }

}
</script>

<style scoped>
  aside{
    width: 13%;
    background: rgb(255, 249, 240);
    border-right: 1px solid rgb(238, 217, 204);
    box-shadow: .5px 0px 11px .2px rgb(238, 217, 204);
    overflow: hidden;
    font-size: 120%;
    font-family: 'Baloo Da 2', cursive;
    font-weight: 700;
  }

  @media (max-width: 1425px) {
    aside{
      width: 25%;
    }
  }

  @media (max-width: 600px) {
    aside{
      width: 60%;
    }
  }

  li{
    padding: 1rem .5rem;
    cursor: pointer;
    text-align: center;
  }

  li:hover{
    background: rgb(255, 243, 224);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  #icon-heart{
    font-size: 10rem;
    color: red;
  }

  li > img{
    width: 70%;
    margin: 0 auto;
  }

</style>