<template>

  <div>
    <div class="loading">
      <img :src="`${this.$store.state.arasaac}pictograms/16697`" alt="">
    </div>
    <div class="flex justify-center">
      <h2 class="phrase">{{phrases[index]}}</h2>
    </div>
  </div>


</template>

<script>
export default {
  name: "PxLoading",

  props: {
    index: null,
  },

  data(){
    return{
      phrases:{
        1 : 'Sacando punta a los lapices...',
        2 : 'Coloreando los pictogramas...',
      }
    }
  }
}
</script>

<style scoped>

  @keyframes beating {
    50%{
      transform: scale(.7);
    }
  }

  .loading{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    animation: beating 3s infinite;
  }

  .phrase{
    margin-top: 2rem;
    font-size: 1.8rem;
    font-family: 'Baloo Da 2', cursive;
    font-weight: 600;
  }


</style>