<template>
<div>

  <form v-if="$store.state.user !== null && $store.state.user.email === 'elcorreoesdejulio@gmail.com'" @submit.prevent="addNewPhrase" class="flex flex-wrap flex-col container">
    <input class="m-2 p-2 border bor" type="text" placeholder="Introduce la frase" v-model="phrase.meaning">
    <input class="m-2 p-2 border bor" type="text" placeholder="Introduce el id"  v-model="ids">
    <input class="bg-green-400 text-white p-4 m-2" type="submit" value="Enviar">
  </form>

  <h1 class="text-4xl text-center pt-8 pb-8">Reproduce frases predefinidas</h1>

  <div class="phrase" v-for="pictos in $store.state.defaultPhrases" :key="pictos.id">
    <div class="flex items-center" @click="pictoTalking(pictos.meaning); deletePhrase(pictos._id);">
      <div class="picto-phrase-container">
        <img v-for="picto in pictos.pictos" :key="picto._id" :src="`${$store.state.arasaac}pictograms/${picto._id}`">
      </div>
      <font-awesome-icon id="play" class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer" icon="play"/>
      <font-awesome-icon v-if="$store.state.user !== null && $store.state.user.email === 'elcorreoesdejulio@gmail.com'" id="trash" class="lg:w-8 lg:h-8 w-6 h-6 cursor-pointer" icon="trash"/>
    </div>
    <span class="meaning-phrase">{{pictos.meaning}}</span>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PxPhrase",

  data(){
    return{
      location: window.location,

      phrase: {
        meaning: null,
        pictos: [],
      },

      ids: [],
    }
  },

  methods:{
    addNewPhrase: function(){
      if (this.ids.length === 0 || this.phrase.meaning === null) return false;

      if (this.ids.includes(' ')){
        let pictoIds = this.ids.split(' ');

        for (let i = 0; i < pictoIds.length; i++){
          for (let picto in this.$store.state.pictos){
            if (this.$store.state.pictos[picto]._id === parseInt(pictoIds[i])){
              this.phrase.pictos.push(this.$store.state.pictos[picto]);
            }
          }
        }
      }else{
        let pictoIds = this.ids;

        for (let picto in this.$store.state.pictos){
          if (this.$store.state.pictos[picto]._id === parseInt(pictoIds)){
            this.phrase.pictos.push(this.$store.state.pictos[picto]);
          }
        }
      }

      let headers = new Headers();
      headers.append("Authorization", localStorage.getItem('token'));
      headers.append("Content-Type", "application/json");

      let body = JSON.stringify(this.phrase);

      let requestOptions = {
        method: 'POST',
        headers,
        body,
        redirect: 'follow'
      };

      fetch(`${this.$store.state.server}phrases/add`, requestOptions)
          .then(response => response.text())
          .then(result => console.log(result))
          .then(() =>{
            this.$store.dispatch("getDefaultPhrases");
          })
          .then(() => {this.phrase.pictos = []})
          .catch(error => console.log('error', error));
    },

    deletePhrase: function (id){
      let target = event.target;
      //dm432 is an value of d Attribute of play icon
      let dM432 = target.getAttribute('d') ? target.getAttribute('d').includes('M432') : false;

      if (dM432 || event.target.id === 'trash'){

        let headers = new Headers()
        headers.append('Authorization', localStorage.getItem('token'))

        fetch(`${this.$store.state.server}phrases/delete/${id}`, {
          method: 'DELETE',
          headers
        }).then(() =>{
          this.$store.dispatch("getDefaultPhrases");
        }).catch(e => console.error(e));

      }
    },

    pictoTalking: function (toSay){
      //dm424 is an value of d Attribute of play icon
      let dM424 = event.target.getAttribute('d') ? event.target.getAttribute('d').includes('M424') : false;

      const setConfigToTalking = function (voices, dAttrVal){
        var utterance = new SpeechSynthesisUtterance();

        let spanish;

        if (event.target.id ==='play' ||  dAttrVal ){
          spanish = voices.filter(response => response.lang.includes('es'));

          utterance.volume = 1;
          utterance.pitch = 1;
          utterance.rate = .9;
          utterance.voice = spanish[0]
          utterance.voiceURI = spanish[0].voiceURI;
          utterance.lang = spanish[0].lang
          utterance.text = toSay;
          speechSynthesis.cancel();
          speechSynthesis.speak(utterance);
        }
      }
      if (this.voices.length === 0){
        this.$store.dispatch('getVoices')
          .then(()=>{
            setConfigToTalking(this.voices, dM424);
          });
      }else{
        setConfigToTalking(this.voices, dM424);
      }



    },

  },

  computed:{
    ...mapState(['pictos', 'defaultPhrases', 'voices']),
  }


}
</script>

<style scoped>

  h1{
    color: rgb(114, 47, 13);
    font-family: 'Baloo Da 2', cursive;
    font-weight: 700;
  }

  img{
    width: 10%;
    margin: .5rem;
  }

  #play{
    margin-right: 3%;
  }

  #trash{
    margin-right: 2%;
  }

  .phrase{
    display: flex;
    padding: 1rem;
    background: rgb(255, 249, 240);
    flex-direction: column;
    border: solid 1px rgb(238, 217, 204);
    width: 50%;
    margin: 1rem auto;
    border-radius: .3rem;
  }

  .meaning-phrase{
    font-family: 'Baloo Da 2', cursive;
    border-top: solid 1px rgb(238, 217, 204);
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: .8rem;
  }

  .picto-phrase-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: .5rem;
  }
  
  @media (max-width: 1420px) {
    .phrase{
      width: 90%;
    }
  }

  @media (max-width: 896px) {
    img{
      width: 15%;
    }
  }

  @media (max-width: 535px) {
    img{
      width: 19%;
    }
    .phrase{
      width: 93%;
    }
  }
</style>