import Vue from 'vue'
import VueRouter from "vue-router";
import Home from '@/views/Home'
import Favs from "@/views/Favs";
import Login from "@/views/Login";
import Me from "@/views/Me";
import Phrase from "@/views/Phrase";

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',

    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/favoritos',
            name: 'favourites',
            component: Favs
        },
        {
            path: '/frases',
            name: 'phrase',
            component: Phrase
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/me',
            name: 'me',
            component: Me
        }
    ]
})